import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";

import authenticatedFetch from "../utils/fetch"

import DateTime from "react-datetime";

const NextActionDateCell = ({props, backgroundColor, currentDate, selectedDate}) => {
    const [mouseOver, setMouseOver] = useState(false);

    var color = '#FEFFFF';
    var fontWeight = 'normal';
    if (currentDate.isSame(selectedDate, 'day'))
    {
        color = '#17252A';
        fontWeight = 'bold';
    }

    if (mouseOver)
    {
        color = '#2B7A78';
    }

    return (
        <td {...props} className={`${props.className}`} style={{backgroundColor: backgroundColor, color: color, fontWeight: fontWeight}} onMouseOver={() => {setMouseOver(true)}} onMouseOut={() => {setMouseOver(false)}}>
            {currentDate.date()}
        </td>
    );
}

const UserPicker = ({taskId, disabled, localAssignees, selectedUserId, handleUserChanged}) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState([]);

    // Task builder modal sends these in as null to start
    if (!localAssignees)
    {
        localAssignees = [];
    }

    const selectedAssignees = localAssignees.map(assignee => assignee.value);

    useEffect(() => {
        authenticatedFetch({
          path: "task/duecountusers?selectedAssignees=" + selectedAssignees.join(","),
          additionalHeaders: {"taskId": taskId},
          successHandler: function(result) {
            setIsLoaded(true);
            setData(result);
            handleUserChanged(result[0].userId);
          },
          errorHandler: function(error) {
            setIsLoaded(true);
            setError(error);
          }
        });
    }, [localAssignees]);

    if (!isLoaded)
    {
        return (<Form.Select size="sm" disabled={true}><option value={0} key={0}>Loading...</option></Form.Select>);
    }
    else if (error || !data)
    {
        return (<Form.Select size="sm" disabled={true}><option value={0} key={0}>Error... Please reload the page...</option></Form.Select>);
    }
    else
    {
      let userOptionList = [];
      userOptionList.push(data.map((user, i) => {
        var label = "Show calendar for: "  + user.firstName + ' ' + user.lastName;
        if (user.isH3Admin)
        {
            label = label + " (H3)";
        }
        return (
          <option value={user.userId} key={user.userId}>{label}</option>
        )
      }, this));
  
      return (
        <Form.Select size="sm" disabled={disabled} onChange={(e) => {handleUserChanged(e.target.value, e)}} value={selectedUserId}>{userOptionList}</Form.Select>
      )
    }
};

const NextActionDatePicker = ({taskId, disabled, localDue, handleDueChanged, localAssignees}) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState([]);

    const [userId, setUserId] = useState(0);

    const handleUserChanged = (userId, e) => {
        if (e != null) {
            e.preventDefault();
        }

        setUserId(userId);
    }

    const additionalHeaders = {};
    if (taskId)
    {
        additionalHeaders["taskId"] = taskId;
    }

    useEffect(() => {
        if (userId > 0)
        {
            authenticatedFetch({
                path: "task/duecounts/" + userId,
                additionalHeaders: additionalHeaders,
                successHandler: function(result) {
                    setIsLoaded(true);
                    setData(result);
                },
                errorHandler: function(error) {
                    setIsLoaded(true);
                    setError(error);
                }
            });
        }
    }, [userId])

    const dueMoment = DateTime.moment(localDue);

    const onDueChanged = (dueMoment) => {
        if (dueMoment && typeof dueMoment == 'object') 
        {
            handleDueChanged(dueMoment.format('YYYY-MM-DD'));
        }
        else
        {
            handleDueChanged('');
        }
    }

    const renderDay = (props, currentDate, selectedDate) => {
        const dateStr = currentDate.format('YYYY-MM-DD');

        var opacity = '.7';
        if (props.className.includes('rdtOld') || props.className.includes('rdtNew')) 
        {
            opacity = '.5';
        }
      
        let backgroundColor = `rgb(58, 175, 169, ${opacity})`;
        if (data && data["counts"] && dateStr in data["counts"] && data["counts"][dateStr] > data['dangerThreshold'])
          backgroundColor = `rgb(217, 83, 79, ${opacity})`;
        else if (data && data["counts"] && dateStr in data["counts"] && data['counts'][dateStr] > data['warnThreshold'])
          backgroundColor = `rgb(229, 165, 75, ${opacity})`;
  
        return (
          <NextActionDateCell key={dateStr} props={props} backgroundColor={backgroundColor} currentDate={currentDate} selectedDate={selectedDate} />
        );
    };

    const renderView = (mode, renderDefault) => {
        if (mode === "time") return renderDefault();

        return (
          <div style={{padding: "15px", backgroundColor: "#FEFFFF"}}>
            <div style={{padding: "0 5px 10px 5px"}}>
                <UserPicker taskId={taskId} disabled={disabled} localAssignees={localAssignees} selectedUserId={userId} handleUserChanged={handleUserChanged} />
            </div>
            {renderDefault()}
          </div>
        );
    };

    return (
        <DateTime 
            inputProps={{disabled: disabled, placeholder: "mm/dd/yyyy"}} 
            value={dueMoment} 
            timeFormat={false} 
            closeOnSelect={true} 
            renderDay={renderDay}
            renderView={renderView}
            onChange={(value) => {onDueChanged(value)}} />
    )
}

export default NextActionDatePicker;