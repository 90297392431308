import React, {useState, useEffect} from "react";
import * as Yup from "yup";
import { useFormikContext, Formik, useField } from "formik";
import { Button, Form} from "react-bootstrap";
import InputMask from "react-input-mask";
import usStateOptions from "../../utils/usStateOptions";
import rawMaritalStatuses from "../../utils/maritalStatuses";
import Spinner from 'react-bootstrap/Spinner';
import Select from "react-select";

import { Flag } from "react-feather";

import isFileSizeUnderLimit from "../../utils/fileUploadSize";
import getOnboardingStages from "../../utils/onboardingStages";

const { REACT_APP_BACKEND_URL } = process.env;


const SpecialtySelect = ({ handleSpecialtiesChanged, selectedSpecialties, isInvalid, handleBlur, forceRefresh}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(REACT_APP_BACKEND_URL + "specialty", {method: "GET"})
    .then(res => {
        if (res.ok)
        {
            return res.json();
        }

        throw Error(res.status);
    })
    .then(
      (result) => {
        setIsLoaded(true);
        setData(result);
      },
      (error) => {
        setIsLoaded(true);
        setError(error);
      }
    )
  }, [])

  const options = React.useMemo(() => {
      const options = new Set();
      if(isLoaded && !error && data)
      {
        data.forEach((specialty) => {
          options.add({value: specialty.id, label: specialty.code + ": " + specialty.name});
        });
        return [...options.values()];
      }
      return []
  }, [data]);

  if (!isLoaded)
  {
    return (<Form.Select disabled={true}><option value={0} key={0}>Loading...</option></Form.Select>);
  }
  else if (error || !data)
  {
    return (<Form.Select disabled={true}><option value={0} key={0}>Error... Please reload the page...</option></Form.Select>);
  }
  else
  {
      const customStyles = {
        dropdownIndicator: (styles) => ({
            ...styles,
            padding: "0 5px 0 0",
        }),
        clearIndicator: (styles) => ({
            ...styles,
            padding: 0,
        }),
        // control: (styles, state) => ({
        //   ...styles,
        //   borderColor: state.isFocused ? 'red' : 'red',
        //   // border: "5px solid blue",
        //   // borderColor: "#d9534f",
        //   // boxShadow: "none"
        // })
      }

      // : "#ced4da",

      return (
          <div>
              <Select
              name="specialties"
              id="specialties"
              options={options}
              styles={customStyles}
              // classNames={{
              //   control: (state) =>
              //     state.isFocused ? 'border-red-600' : 'border-red-300',
              // }}
              onBlur={handleBlur}
              onChange={(values) => {
                handleSpecialtiesChanged(values);
              }}
              value={selectedSpecialties}
              placeholder="Select specialties..."
              isMulti
              theme={(theme) => ({
                ...theme,
                colors: {
                ...theme.colors,
                  primary50: '#DEF2F1',
                  primary25: '#DEF2F1',
                  primary: '#3AAFA9',
                },
              })}
              />
          </div>
      )
  }
}

const SpecialtySelectWrapper = () => {
    const { values, errors, touched } = useFormikContext();
    const [forceSpecialtyRefresh, setForceSpecialtyRefresh] = useState(0);

    const [field, state, { setValue, setTouched }] = useField("specialties");

    var selectedSpecialtiesTransformed = [];
    for(var i = 0; i < values.specialties.length; i++)
    {
      selectedSpecialtiesTransformed.push({value: values.specialties[i].id, label: values.specialties[i].code + ": " + values.specialties[i].name});
    }
    
    const handleSpecialtiesChanged = (selectedSpecialties)  => {
      var specialties = [];
      for(var i=0; i<selectedSpecialties.length; i++)
      {
        var index = selectedSpecialties[i].label.indexOf(":");
        var code = selectedSpecialties[i].label.substring(0, index);
        var name = selectedSpecialties[i].label.substring(index+2);
  
        specialties.push({id: selectedSpecialties[i].value, code: code, name: name});
      }
  
      setValue(specialties)

      setForceSpecialtyRefresh(forceSpecialtyRefresh + 1);
    }

    return (
      <SpecialtySelect handleBlur={setTouched} isInvalid={Boolean(errors.specialties)}  forceRefresh={forceSpecialtyRefresh} handleSpecialtiesChanged={handleSpecialtiesChanged} selectedSpecialties={selectedSpecialtiesTransformed}/>
    );          
}


const OnboardingDemographics = ({stage, token, data}) => {
  let usStateOptionList = usStateOptions.map((state) => {
    var label = state.label === "" ? "State..." : state.label;
    return (
      <option value={state.value} key={state.value}>{label}</option>
    )
  }, this);

  let maritalStatusOptionList = rawMaritalStatuses.map((maritalStatus) => {
    return (
      <option value={maritalStatus.value == null ? "" : maritalStatus.value} key={maritalStatus.value}>{maritalStatus.label}</option>
    )
  }, this);
  
  let onboardingStages = getOnboardingStages(data.preferences);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        personalEmail: data.personalEmail == null ? "" : data.personalEmail,
        cellPhone: data.cellPhone == null ? "" : data.cellPhone,
        otherPhone: data.otherPhone == null ? "" : data.otherPhone,
        pager: data.pager == null ? "" : data.pager,
        dob: data.dob == null ? "" : data.dob,
        ssn: data.ssn == null ? "" : data.ssn,
        credentials: data.credentials == null ? "" : data.credentials,
        specialties: data.specialties == null ? [] : data.specialties,
        noCaqh: data.noCaqh == null ? false : data.noCaqh,
        caqhId: data.caqhId == null ? "" : data.caqhId,
        caqhUsername: data.caqhUsername == null ? "" : data.caqhUsername,
        caqhPassword: data.caqhPassword == null ? "" : data.caqhPassword,
        dea: data.dea == null ? "" : data.dea,
        deaExpirationDate: data.deaExpirationDate == null ? "" : data.deaExpirationDate,
        noDea: data.noDea == null ? false : data.noDea,
        deaFile: null,
        deaFilename: data.deaFilename == null ? "" : data.deaFilename,
        noMedicaidId: data.noMedicaidId == null ? false : data.noMedicaidId,
        medicaidId: data.medicaidId == null ? "" : data.medicaidId,
        noPtan: data.noPtan == null ? false : data.noPtan,
        ptan: data.ptan == null ? "" : data.ptan,
        currentStreet1: data.currentStreet1 == null ? "" : data.currentStreet1,
        currentStreet2: data.currentStreet2 == null ? "" : data.currentStreet2,
        currentCity: data.currentCity == null ? "" : data.currentCity,
        currentState: data.currentState == null ? "" : data.currentState,
        currentZip: data.currentZip == null ? "" : data.currentZip,
        priorAddresses: data.priorAddresses == null ? "" : data.priorAddresses,
        birthplaceCity: data.birthplaceCity == null ? "" : data.birthplaceCity,
        birthplaceState: data.birthplaceState == null ? "" : data.birthplaceState,
        citizenship: data.citizenship == null ? "" : data.citizenship,
        maritalStatus: data.maritalStatus == null ? "" : data.maritalStatus,
        spouse: data.spouse == null ? "" : data.spouse,
        priorNamesAndDates: data.priorNamesAndDates == null ? "" : data.priorNamesAndDates,
        noNpi: data.noNpi == null ? false : data.noNpi,
        npi: data.npi == null ? "" : data.npi,
        npiUsername: data.npiUsername == null ? "" : data.npiUsername,
        npiPassword: data.npiPassword == null ? "" : data.npiPassword,
      }}
      validationSchema={Yup.object().shape({
        personalEmail: Yup.string().email("Must be a valid email")
          .max(255)
          .required("Personal email is required"),
        cellPhone: Yup.string().required('Cell phone is required')
          .transform(value => value.replace(/[^\d]/g, ''))
          .matches(
            /^\d{10}$/,
            "Must be valid phone number"
          ),
        otherPhone: Yup.string(),
        // .transform(value => value.replace(/[^\d]/g, ''))
        // .matches(
        //   /^\d{10}$/,
        //   "Please enter valid other phone number"
        // ),
        pager: Yup.string(),
        // .transform(value => value.replace(/[^\d]/g, ''))
        // .matches(
        //   /^\d{10}$/,
        //   "Please enter valid pager number"
        // ),
        dob: Yup.date().required("Date of birth is required"),
        ssn: Yup.string().required("SSN is required")
          .transform(value => value.replace(/[^\d]/g, ''))
          .matches(
            /^\d{9}$/,
            "Must be valid SSN"
          ),
        credentials: Yup.string()
          .max(255)
          .required("Credentials are required"),
        specialties: Yup.array()
          .min(1, "At least one specialty is required")
          .required("Specialties are required"),
        noCaqh: Yup.boolean(),
        caqhId: Yup.string().max(255).when("noCaqh", {
          is: false,
          then: Yup.string().required("CAQH ID is required"),
          otherwise: Yup.string()
        }),
        caqhUsername: Yup.string().max(255).when("noCaqh", {
          is: false,
          then: Yup.string().required("CAQH username is required"),
          otherwise: Yup.string()
        }),
        caqhPassword: Yup.string().max(255).when("noCaqh", {
          is: false,
          then: Yup.string().required("CAQH password is required"),
          otherwise: Yup.string()
        }),
        noDea: Yup.boolean(),
        dea: Yup.string().max(255).when("noDea", {
          is: false,
          then: Yup.string().required("DEA is required"),
          otherwise: Yup.string()
        }),
        deaExpirationDate: Yup.date().when("noDea", {
          is: false,
          then: Yup.date().required("DEA expiration date is required"),
          otherwise: Yup.date()
        }),
        deaFile: Yup.mixed().when(["noDea", "deaFilename"], {
          is: (noDea, deaFilename) => noDea === false && (!deaFilename || deaFilename === ""),
          then: Yup.mixed().required("DEA document is required"),
          otherwise: Yup.mixed()
        })
        .test(
          "fileSize",
          "File size too large; max file size is 10 Mb",
          isFileSizeUnderLimit
        ),
        noMedicaidId: Yup.boolean(),
        medicaidId: Yup.string().max(255).when("noMedicaidId", {
          is: false,
          then: Yup.string().required("Medicaid ID is required"),
          otherwise: Yup.string()
        }),

        noPtan: Yup.boolean(),
        ptan: Yup.string().max(255).when("noPtan", {
          is: false,
          then: Yup.string().required("PTAN is required"),
          otherwise: Yup.string()
        }),

        currentStreet1: Yup.string().required("Current address is required").max(255),
        currentCity: Yup.string().required("Current address is required").max(255),
        currentState: Yup.string().required("Current address is required").max(255),
        currentZip: Yup.string().required("Current address is required")
        .matches(/^\d{5}$/, "Please enter valid zip code"),
        birthplaceCity: Yup.string().required("Birthplace city is required").max(255),
        birthplaceState: Yup.string().required("Birthplace state is required (select Other if born outside the U.S.)").max(255),
        citizenship: Yup.string().required("Citizenship is required").max(255),
        maritalStatus: Yup.string().required("Marital status is required").max(255),
        spouse: Yup.string().max(255).when("maritalStatus", {
          is: value => value === "MARRIED" || value === "SEPARATED",
          then: Yup.string().required("Spouse name is required"),
          otherwise: Yup.string()
        }),
        priorAddresses: Yup.string().required("Prior addresses are required").max(2048),
        
        noNpi: Yup.boolean(),
        npi: Yup.string().max(255).when("noNpi", {
          is: false,
          then: Yup.string().required("NPI is required"),
          otherwise: Yup.string()
        })
        .transform(value => value.replace(/[^\d]/g, ''))
          .matches(
            /^\d{10}$/,
            "Must be valid NPI"
          ),
        npiUsername: Yup.string().max(255).when("noNpi", {
          is: false,
          then: Yup.string().required("NPI username is required"),
          otherwise: Yup.string()
        }),
        npiPassword: Yup.string().max(255).when("noNpi", {
          is: false,
          then: Yup.string().required("NPI password is required"),
          otherwise: Yup.string()
        }),

      })}
      onSubmit={async (values, { setErrors, setStatus, resetForm, setSubmitting }) => {
        try {
          var formData = new FormData();
          formData.append("data", new Blob([JSON.stringify(
            {
              personalEmail: values.personalEmail,
              cellPhone: values.cellPhone,
              otherPhone: values.otherPhone,
              pager: values.pager,
              dob: values.dob,
              ssn: values.ssn,
              credentials: values.credentials,
              specialties: values.specialties,
              caqhId: values.caqhId,
              caqhUsername: values.caqhUsername,
              caqhPassword: values.caqhPassword,
              dea: values.dea,
              deaExpirationDate: values.deaExpirationDate,
              medicaidId: values.medicaidId,
              ptan: values.ptan,
              currentStreet1: values.currentStreet1,
              currentStreet2: values.currentStreet2,
              currentCity: values.currentCity,
              currentState: values.currentState,
              currentZip: values.currentZip,
              priorAddresses: values.priorAddresses,
              birthplaceCity: values.birthplaceCity,
              birthplaceState: values.birthplaceState,
              citizenship: values.citizenship,
              maritalStatus: values.maritalStatus,
              spouse: values.spouse,
              priorNamesAndDates: values.priorNamesAndDates,
              npi: values.npi,
              npiUsername: values.npiUsername,
              npiPassword: values.npiPassword,
              noCaqh: values.noCaqh,
              noDea: values.noDea,
              noMedicaidId: values.noMedicaidId,
              noPtan: values.noPtan,
              noNpi: values.noNpi
            })], {
              type: "application/json"
          }));

          formData.append("deaFile", values.deaFile);
          // console.log({ 
          //   fileName: values.deaFile.name, 
          //   type: values.deaFile.type,
          //   size: `${values.deaFile.size} bytes`
          // })

          const requestMetadata = {
            method: 'POST',
            body: formData
          };

          const res = await fetch(REACT_APP_BACKEND_URL + "onboarding/" + stage + "/" + token, requestMetadata);

          if (res.status >= 200 && res.status <= 299)
          {
            setStatus({success: true})

            window.location = "/onboarding/" + onboardingStages[onboardingStages.indexOf(stage) + 1]  + "/" + token
          }
          else
          {
            throw Error(res.statusText);
          }
        } catch (error) {
          const message = "Something went wrong; please try again";

          setStatus({ success: false });
          alert(message);
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        getFieldProps,
        isSubmitting,
        status,
        touched,
        values,
      }) => 

      (
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label style={{fontWeight: "bold"}}>Personal Email*</Form.Label>
            <Form.Control
              name="personalEmail"
              // AUTO focus breaks the submit touching the fields - see bug report https://github.com/jaredpalmer/formik/issues/1783
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.personalEmail}
              isInvalid={Boolean(touched.personalEmail && errors.personalEmail)}
              />
            {!!touched.personalEmail && (
              <Form.Control.Feedback type="invalid">
                {errors.personalEmail}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{fontWeight: "bold"}}>Cell Phone*</Form.Label>
            <InputMask
              type="tel"
              mask='(999) 999-9999'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.cellPhone}>
              {(inputProps) => <Form.Control
                name="cellPhone"
                {...inputProps} 
                isInvalid={Boolean(touched.cellPhone && errors.cellPhone)}
              />}
            </InputMask>
            {!!touched.cellPhone && (
              <Form.Control.Feedback type="invalid">
                {errors.cellPhone}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{fontWeight: "bold"}}>Other Phone</Form.Label>
            <InputMask
              type="tel"
              mask='(999) 999-9999'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.otherPhone}>
              {(inputProps) => <Form.Control
                name="otherPhone"
                {...inputProps} 
                isInvalid={Boolean(touched.otherPhone && errors.otherPhone)}
              />}
            </InputMask>
            {!!touched.otherPhone && (
              <Form.Control.Feedback type="invalid">
                {errors.otherPhone}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{fontWeight: "bold"}}>Pager</Form.Label>
            <InputMask
              type="tel"
              mask='(999) 999-9999'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.pager}>
              {(inputProps) => <Form.Control
                name="pager"
                {...inputProps} 
                isInvalid={Boolean(touched.pager && errors.pager)}
              />}
            </InputMask>
            {!!touched.pager && (
              <Form.Control.Feedback type="invalid">
                {errors.pager}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{fontWeight: "bold"}}>Date of Birth*</Form.Label>
            <Form.Control
              type="date"
              name="dob"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.dob}
              isInvalid={Boolean(touched.dob && errors.dob)}
            />
            {!!touched.dob && (
              <Form.Control.Feedback type="invalid">
                {errors.dob}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{fontWeight: "bold"}}>SSN*</Form.Label>
            <InputMask
              type="tel"
              mask='999-99-9999'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.ssn}>
              {(inputProps) => <Form.Control
                name="ssn"
                {...inputProps} 
                isInvalid={Boolean(touched.ssn && errors.ssn)}
              />}
            </InputMask>
            {!!touched.ssn && (
              <Form.Control.Feedback type="invalid">
                {errors.ssn}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{fontWeight: "bold"}}>Credentials*</Form.Label>
            <Form.Control
              name="credentials"
              placeholder="MD, DO, APRN, PA..."
              autoComplete="off"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.credentials}
              isInvalid={Boolean(touched.credentials && errors.credentials)}
              />
            {!!touched.credentials && (
              <Form.Control.Feedback type="invalid">
                {errors.credentials}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{fontWeight: "bold"}}>Specialties*</Form.Label>
            <SpecialtySelectWrapper/>
            {!!touched.specialties && (
            <div className="invalid-feedback" style={{display: "block"}}>
              {errors.specialties}
            </div>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{fontWeight: "bold"}}>CAQH ID{values['noCaqh'] ? "" : "*"}&nbsp;&nbsp;(&nbsp;
            <Form.Check
              name="noCaqh"
              style={{display: "inline"}}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.noCaqh}
              checked={values.noCaqh}
            />&nbsp;
            <span style={{fontSize: '0.9em', fontWeight: "normal"}}>I don't have a CAQH ID</span>)</Form.Label>
            <Form.Control
              name="caqhId"
              autoComplete="off"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.caqhId}
              isInvalid={Boolean(touched.caqhId && errors.caqhId)}
              />
            {!!touched.caqhId && (
              <Form.Control.Feedback type="invalid">
                {errors.caqhId}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{fontWeight: "bold"}}>CAQH Username{values['noCaqh'] ? "" : "*"}</Form.Label>
            <Form.Control
              name="caqhUsername"
              autoComplete="off"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.caqhUsername}
              isInvalid={Boolean(touched.caqhUsername && errors.caqhUsername)}
              />
            {!!touched.caqhUsername && (
              <Form.Control.Feedback type="invalid">
                {errors.caqhUsername}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{fontWeight: "bold"}}>CAQH Password{values['noCaqh'] ? "" : "*"}</Form.Label>
            <Form.Control
              type="text"
              style={{WebkitTextSecurity: "disc" }}
              autoComplete="off"
              name="caqhPassword"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.caqhPassword}
              isInvalid={Boolean(touched.caqhPassword && errors.caqhPassword)}
              />
            {!!touched.caqhPassword && (
              <Form.Control.Feedback type="invalid">
                {errors.caqhPassword}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{fontWeight: "bold"}}>DEA{values['noDea'] ? "" : "*"}&nbsp;&nbsp;(&nbsp;
            <Form.Check
              name="noDea"
              style={{display: "inline"}}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.noDea}
              checked={values.noDea}
            />&nbsp;
            <span style={{fontSize: '0.9em', fontWeight: "normal"}}>I don't have a DEA</span>)</Form.Label>
            <Form.Control
              name="dea"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.dea}
              isInvalid={Boolean(touched.dea && errors.dea)}
              />
            {!!touched.dea && (
              <Form.Control.Feedback type="invalid">
                {errors.dea}
              </Form.Control.Feedback>
            )}
            <br />
            <Form.Label style={{fontSize: "0.9em"}}>Expiration Date{values['noDea'] ? "" : "*"}</Form.Label>
            <Form.Control
              type="date"
              {...getFieldProps("deaExpirationDate")} 
              isInvalid={Boolean(touched.deaExpirationDate && errors.deaExpirationDate)}
            />
            {!!touched.deaExpirationDate && (
            <Form.Control.Feedback type="invalid">
              {errors.deaExpirationDate}
            </Form.Control.Feedback>
            )}
            <br />
            {values.deaFilename && <Form.Label>(Current: <span style={{wordBreak: "break-all"}}>{values.deaFilename}</span>)</Form.Label>}
            <Form.Control
                  type="hidden"
                  name="deaFilename"
                  value={values.deaFilename}
                />
            <Form.Control
              type="file"
              name="deaFile"
              onBlur={handleBlur}
              // Formik doesn't handle files out of the box, have to do this by hand
              onChange={(event) => {
                const file = event.currentTarget.files.length > 0 ? event.currentTarget.files[0] : null;
                setFieldValue("deaFile", file);
              }}
              // value={values.deaFile}
              isInvalid={Boolean(touched.deaFile && errors.deaFile)}
              />
            {!!touched.deaFile && (
              <Form.Control.Feedback type="invalid">
                {errors.deaFile}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3" style={{marginTop: "20px"}}>
            <Form.Label style={{fontWeight: "bold"}}>Medicaid ID{values['noMedicaidId'] ? "" : "*"}&nbsp;&nbsp;(&nbsp;
            <Form.Check
              name="noMedicaidId"
              style={{display: "inline"}}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.noMedicaidId}
              checked={values.noMedicaidId}
            />&nbsp;
            <span style={{fontSize: '0.9em', fontWeight: "normal"}}>I don't have a Medicaid ID</span>)</Form.Label>
            <Form.Control
              name="medicaidId"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.medicaidId}
              isInvalid={Boolean(touched.medicaidId && errors.medicaidId)}
              />
            {!!touched.medicaidId && (
              <Form.Control.Feedback type="invalid">
                {errors.medicaidId}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{fontWeight: "bold"}}>PTAN{values['noPtan'] ? "" : "*"}&nbsp;&nbsp;(&nbsp;
            <Form.Check
              name="noPtan"
              style={{display: "inline"}}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.noPtan}
              checked={values.noPtan}
            />&nbsp;
            <span style={{fontSize: '0.9em', fontWeight: "normal"}}>I don't have a PTAN</span>)</Form.Label>
            <Form.Control
              name="ptan"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.ptan}
              isInvalid={Boolean(touched.ptan && errors.ptan)}
            />
            {!!touched.ptan && (
              <Form.Control.Feedback type="invalid">
                {errors.ptan}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{fontWeight: "bold"}}>Current Address*</Form.Label>
            <br />
            <Form.Control
              name="currentStreet1"
              placeholder="Street 1"
              style={{ width: "48%", display: "inline", marginRight: "4%"}}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.currentStreet1}
              isInvalid={Boolean(touched.currentStreet1 && errors.currentStreet1)}
            />
            <Form.Control
              name="currentStreet2"
              placeholder="Street 2"
              style={{ width: "48%", display: "inline"}}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.currentStreet2}
              isInvalid={Boolean(touched.currentStreet2 && errors.currentStreet2)}
            />
            <Form.Control
              name="currentCity"
              placeholder="City"
              style={{ marginTop: "30px", width: "48%", display: "inline", marginRight: "4%"}}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.currentCity}
              isInvalid={Boolean(touched.currentCity && errors.currentCity)}
            />
            <Form.Select
              name="currentState"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.currentState} 
              isInvalid={Boolean(touched.currentState && errors.currentState)}
              style={{marginTop: "30px", width: "20%", marginRight: "4%", display: "inline"}}>
                {usStateOptionList}
            </Form.Select>
            <InputMask
              style={{marginTop: "30px", width: "24%", display: "inline"}}
              type="tel"
              mask='99999'
              placeholder="Zip"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.currentZip}>
              {(inputProps) => <Form.Control
                name="currentZip"
                {...inputProps} 
                isInvalid={Boolean(touched.currentZip && errors.currentZip)}
              />}
            </InputMask>
            {(!!touched.currentStreet1 || !!touched.currentStreet2 || !!touched.currentCity || !!touched.currentState || !!touched.currentZip) && (
              <Form.Control.Feedback type="invalid">
                {errors.currentStreet1 || errors.currentStreet2 || errors.currentCity || errors.currentState || errors.currentZip}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{fontWeight: "bold"}}>Prior Addresses*
            <br /><span style={{fontSize: '0.9em', fontWeight: "normal"}}><Flag style={{height: "16px", width: "16px", color: "#2B7A78"}} /> Please list all addresses you have lived at for the past seven years, as well as <b>dates</b> for each address</span>
            </Form.Label>
            <Form.Control
              style={{ height: "100px" }}
              as="textarea"
              name="priorAddresses"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.priorAddresses}
              isInvalid={Boolean(touched.priorAddresses && errors.priorAddresses)}
            />
            {!!touched.priorAddresses && (
              <Form.Control.Feedback type="invalid">
                {errors.priorAddresses}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{fontWeight: "bold"}}>Birthplace*</Form.Label>
            <br />
            <Form.Control
              name="birthplaceCity"
              placeholder="City"
              style={{ width: "71%", display: "inline", marginRight: "4%"}}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.birthplaceCity}
              isInvalid={Boolean(touched.birthplaceCity && errors.birthplaceCity)}
            />
            <Form.Select
              name="birthplaceState"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.birthplaceState} 
              isInvalid={Boolean(touched.birthplaceState && errors.birthplaceState)}
              style={{width: "25%", display: "inline"}}>
                {usStateOptionList}
            </Form.Select>
            {(!!touched.birthplaceCity || !touched.birthplaceState) && (
              <Form.Control.Feedback type="invalid">
                {errors.birthplaceCity || errors.birthplaceState}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{fontWeight: "bold"}}>Citizenship*</Form.Label>
            <Form.Control
              name="citizenship"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.citizenship}
              isInvalid={Boolean(touched.citizenship && errors.citizenship)}
            />
            {!!touched.citizenship && (
              <Form.Control.Feedback type="invalid">
                {errors.citizenship}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{fontWeight: "bold"}}>Marital Status*</Form.Label>
            <Form.Select
              name="maritalStatus"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.maritalStatus} 
              isInvalid={Boolean(touched.maritalStatus && errors.maritalStatus)}>
                {maritalStatusOptionList}
            </Form.Select>
            {!!touched.maritalStatus && (
              <Form.Control.Feedback type="invalid">
                {errors.maritalStatus}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{fontWeight: "bold"}}>Spouse Name{values['maritalStatus'] === "MARRIED" || values['maritalStatus'] === "SEPARATED" ? "*" : ""}</Form.Label>
            <Form.Control
              name="spouse"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.spouse}
              isInvalid={Boolean(touched.spouse && errors.spouse)}
            />
            {!!touched.spouse && (
              <Form.Control.Feedback type="invalid">
                {errors.spouse}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{fontWeight: "bold"}}>Prior Names and Dates</Form.Label>
            <Form.Control
              style={{ height: "100px" }}
              as="textarea"
              name="priorNamesAndDates"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.priorNamesAndDates}
              isInvalid={Boolean(touched.priorNamesAndDates && errors.priorNamesAndDates)}
            />
            {!!touched.priorNamesAndDates && (
              <Form.Control.Feedback type="invalid">
                {errors.priorNamesAndDates}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
          <Form.Label style={{fontWeight: "bold"}}>NPI{values['noNpi'] ? "" : "*"}&nbsp;&nbsp;(&nbsp;
            <Form.Check
              name="noNpi"
              style={{display: "inline"}}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.noNpi}
              checked={values.noNpi}
            />&nbsp;
            <span style={{fontSize: '0.9em', fontWeight: "normal"}}>I don't have an NPI yet</span>)</Form.Label>
            <InputMask
              type="tel"
              mask='9999999999'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.npi}>
              {(inputProps) => <Form.Control
                name="npi"
                {...inputProps} 
                isInvalid={Boolean(touched.npi && errors.npi)}
              />}
            </InputMask>
            {!!touched.npi && (
              <Form.Control.Feedback type="invalid">
                {errors.npi}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{fontWeight: "bold"}}>NPI Username{values['noNpi'] ? "" : "*"}</Form.Label>
            <Form.Control
              name="npiUsername"
              autoComplete="off"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.npiUsername}
              isInvalid={Boolean(touched.npiUsername && errors.npiUsername)}
            />
            {!!touched.npiUsername && (
              <Form.Control.Feedback type="invalid">
                {errors.npiUsername}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{fontWeight: "bold"}}>NPI Password{values['noNpi'] ? "" : "*"}</Form.Label>
            <Form.Control
              type="text"
              style={{WebkitTextSecurity: "disc" }}
              name="npiPassword"
              autoComplete="off"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.npiPassword}
              isInvalid={Boolean(touched.npiPassword && errors.npiPassword)}
              />
            {!!touched.npiPassword && (
              <Form.Control.Feedback type="invalid">
                {errors.npiPassword}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <div id="submitButton" className="text-center mt-3">
            <br />
            {isSubmitting ?
            <Spinner size = "sm" animation="border" />
            :
              <Button
                type="submit"
                variant="primary"
                size="lg"
                disabled={isSubmitting}
              >
                Save and Continue
              </Button>
              }
          </div>
          {
            Object.keys(errors).length > 0 && Object.keys(touched).length > 0 &&
            <div className="text-center mt-3"><span className="invalid-feedback" style={{display: "inline-block"}}>Please correct the errors above before moving forward</span></div>
          }
        </Form>
        
      )}
    </Formik>
  );
}

export default OnboardingDemographics;
