import React, { useState, useEffect, useContext } from "react";
import { Badge, Col, Card, Row, Modal, Table, Button, Spinner } from "react-bootstrap";
import NotyfContext from "../../../contexts/NotyfContext";
import { Clock, FileText, BookOpen } from "react-feather";

import illustration from "../../../assets/img/illustrations/checklist.png";

import authenticatedFetch from "../../../utils/fetch"
import getRawDocumentCategories from "../../../utils/documentCategories";

import { UserSettingsContext } from "../../../contexts/UserSettingsContext";

const Greeting = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    authenticatedFetch({
      path: "user",
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });
  }, [])

  if (!isLoaded)
  {
    return (<>Welcome...</>);
  }
  else if (error || !data)
  {
    return (<>Welcome!</>);
  }
  else
  {
    return (
      <>
        Welcome, {data.firstName}!
      </>
    )
  }
}

const RenewalModal = ({show, onClose, renewalList}) => {
  const handleClick = (url) => 
  {
    window.location = url;
  }
  
  const renewalRows = renewalList && renewalList.length > 0
    && renewalList.map((renewal, i) => {
      var parts = renewal.expiration.split("-");
      var expiresDate = new Date(parts[0], parts[1] - 1, parts[2]);  // Avoid the UTC->local timezone translation issue with js

      return (
        <tr key={i} style={{cursor: "pointer"}} onClick={(e) => handleClick("/" + renewal.entityType + "/" + renewal.entityId)}>
          <td>{renewal.entityFormattedName}</td>
          <td style={{whiteSpace:"nowrap"}}>{expiresDate.toLocaleDateString("en-US")}</td>
          <td>{renewal.dataHubName}</td>
        </tr>
        )
  }, this);

  return (
    <Modal show={show} onHide={onClose} scrollable={true}>
    <Modal.Header closeButton style={{backgroundColor: '#DEF2F1'}}>
      <Modal.Title>
        Data Hub Attestation{renewalList && renewalList.length > 1 ? "s" : ""} Due in Next 60 Days
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Table striped bordered hover size="sm"> 
        <thead>
          <tr>
            <td>Practice / Provider</td>
            <td>Attestation Date</td>
            <td>Data Hub</td>
          </tr>
        </thead>
        <tbody>
          {renewalRows} 
        </tbody>
      </Table>
    </Modal.Body>
    <Modal.Footer style={{backgroundColor: '#DEF2F1'}}>
      <Button onClick={onClose}>Close</Button>
    </Modal.Footer>
  </Modal>
  )
}


const RenewalCount = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  const [show, setShow] = useState(false);
  const handleShow = () => 
  {
    if (data.length > 0)
      setShow(true);
  }
  const handleClose = () => setShow(false);

  useEffect(() => {
    authenticatedFetch({
      path: "provider/upcomingrenewalcount",
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });
  }, [])

  if (!isLoaded)
  {
    return (<>Loading...</>);
  }
  else if (error || data == null)
  {
    return (<>...</>);
  }
  else
  {
    return (
      <>
        <div className="flex-grow-1" style={{cursor: data && data.length > 0 ? 'pointer': 'default'}}  onClick={(e) => handleShow()}>
          <h3 className="mb-2">{!data ? 0 : data.length} Upcoming Attestation{!data || data.length === 0 || data.length > 1 ? "s" : ""}</h3>
          { data && data.length > 0 ?
          <>
            <p className="mb-2">Data hub attestations must be completed to remain current</p>
            <div className="mb-0">
              <span className="text-muted" style={{fontSize:"0.9em"}}>Due in </span>
                <Badge bg="" className="badge-soft-danger me-2">
                  &lt; 60 days
                </Badge>
            </div>
            </> 
            : <><p className="mb-2">No upcoming data hub attestations!</p></>
          }
        </div>
        <div className="d-inline-block ms-3">
          <div className="">
          { data && data.length > 0 ?
              <Clock className="align-middle text-danger" />
            : <Clock className="align-middle" style={{color: '#3AAFA9'}}/>
            }
          </div>
        </div>
        <RenewalModal show={show} onClose={handleClose} renewalList={data}/>
      </>
    )
  }
}

const ExpiringDocumentRow = ({doc, reload}) => {
  const [isMuting, setIsMuting] = useState(false);
  const { isH3Admin, authorities } = useContext(UserSettingsContext);
  let rawDocumentCategories = getRawDocumentCategories(isH3Admin, authorities);
  
  const notyf = useContext(NotyfContext);

  const handleClick = (url) => 
  {
    window.location = url;
  }

  const handleMute = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsMuting(true);

    authenticatedFetch({
      method: "POST",
      path: "provider/muteupcomingdocumentexpirationalert",
      requestData: {
        entityType: doc.entityType,
        entityId: doc.entityId,
        filename: doc.filename
      },
      successHandler: function(result) {
        setIsMuting(false);
        reload();
      },
      errorHandler: function(error) {
        setIsMuting(false);
        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });
  }

  var categoryLabel = '';
  if (doc.category)
  {
    for (let i = 0; i < rawDocumentCategories.length; i++)
    {
      if (doc.category === rawDocumentCategories[i].value)
      {
        categoryLabel = rawDocumentCategories[i].label;
        break;
      }
    }
  }

  var parts = doc.expiration.split("-");
  var expiresDate = new Date(parts[0], parts[1] - 1, parts[2]);  // Avoid the UTC->local timezone translation issue with js

  return (
    <tr style={{cursor: "pointer"}} onClick={(e) => handleClick("/" + doc.entityType + "/" + doc.entityId)}>
      <td>{doc.entityFormattedName}</td>
      <td><Badge pill bg="secondary">{categoryLabel}</Badge></td>
      <td style={{whiteSpace:"nowrap"}}>{expiresDate.toLocaleDateString("en-US")}</td>
      <td style={{overflow:"hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "220px"}}>{doc.filename}</td>
      <td style={{minWidth: "50px"}}>
        {isMuting ? <Spinner animation="border" size="sm" variant="primary" style={{marginLeft: "15px"}} />
        :
        <Button variant="secondary" size="sm" onClick={(e) => handleMute(e)}>Mute</Button>
        }
      </td>
    </tr>
    )
}

const ExpiringDocumentsModal = ({show, onClose, documentList, reload}) => {
  
  
  const documentRows = documentList && documentList.length > 0
    && documentList.map((doc, i) => {
      return (
        <ExpiringDocumentRow key={i} doc={doc} reload={reload}/>
        )
  }, this);

  return (
    <Modal show={show} onHide={onClose} scrollable={true}>
    <Modal.Header closeButton style={{backgroundColor: '#DEF2F1'}}>
      <Modal.Title>
        Document{documentList && documentList.length > 1 ? "s" : ""} Expiring Soon
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Table striped bordered hover size="sm"> 
        <thead>
          <tr>
            <td>Practice / Provider</td>
            <td>Category</td>
            <td>Expiration Date</td>
            <td>Document Name</td>
            <td>&nbsp;</td>
          </tr>
        </thead>
        <tbody>
          {documentRows} 
        </tbody>
      </Table>
    </Modal.Body>
    <Modal.Footer style={{backgroundColor: '#DEF2F1'}}>
      <Button onClick={onClose}>Close</Button>
    </Modal.Footer>
  </Modal>
  )
}

const DocumentExpirationCount = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  const [show, setShow] = useState(false);
  const handleShow = () => 
  {
    if (data.length > 0)
      setShow(true);
  }
  const handleClose = () => setShow(false);

  useEffect(() => {
    reload();
  }, [])

  const reload = () => {
    authenticatedFetch({
      path: "provider/upcomingdocumentexpirationcount",
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });
  }

  if (!isLoaded)
  {
    return (<>Loading...</>);
  }
  else if (error || data == null)
  {
    return (<>...</>);
  }
  else
  {
    return (
      <>
        <div className="flex-grow-1" style={{cursor: data && data.length > 0 ? 'pointer': 'default'}}  onClick={(e) => handleShow()}>
        <h3 className="mb-2">{!data ? 0 : data.length} Document{!data || data.length === 0 || data.length > 1 ? "s" : ""} Expiring Soon</h3>
        { data && data.length > 0 ?
          <>
            <p className="mb-2">Updates may be needed to avoid contract lapses</p>
            <div className="mb-0">
              <span className="text-muted" style={{fontSize:"0.9em"}}>Expiring </span>
                <Badge bg="" className="badge-soft-danger me-2">
                    soon
                </Badge>
            </div>
          </>
        : <><p className="mb-2">No items expiring soon!</p></>
        }
        </div>
        <div className="d-inline-block ms-3">
          <div className="">
            { data && data.length > 0 ?
              <FileText className="align-middle text-danger" />
            : <FileText className="align-middle" style={{color: '#3AAFA9'}} />
            }
          </div>
        </div>
        <ExpiringDocumentsModal show={show} onClose={handleClose} documentList={data} reload={reload}/>
      </>
    )
  }
}


const InsufficientCmesModal = ({show, onClose, providerList}) => {
  const handleClick = (url) => 
  {
    window.location = url;
  }
  
  const providerRows = providerList && providerList.length > 0
    && providerList.map((provider, i) => {
      return (
        <tr key={i} style={{cursor: "pointer"}} onClick={(e) => handleClick("/provider/" + provider.providerId)}>
          <td>{provider.providerFormattedName}</td>
          <td>{Number((provider.totalHours).toFixed(2))}</td>
        </tr>
        )
  }, this);

  return (
    <Modal show={show} onHide={onClose} scrollable={true}>
    <Modal.Header closeButton style={{backgroundColor: '#DEF2F1'}}>
      <Modal.Title>
        Provider{providerList && providerList.length > 1 ? "s" : ""} with Fewer than 50 CME Hours in Past 24 Months
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Table striped bordered hover size="sm"> 
        <thead>
          <tr>
            <td>Provider</td>
            <td>Total Hours</td>
          </tr>
        </thead>
        <tbody>
          {providerRows} 
        </tbody>
      </Table>
    </Modal.Body>
    <Modal.Footer style={{backgroundColor: '#DEF2F1'}}>
      <Button onClick={onClose}>Close</Button>
    </Modal.Footer>
  </Modal>
  )
}

const InsufficientCmeCount = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  const [show, setShow] = useState(false);
  const handleShow = () => 
  {
    if (data.length > 0)
      setShow(true);
  }
  const handleClose = () => setShow(false);

  useEffect(() => {
    authenticatedFetch({
      path: "education/insufficienttotal",
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });
  }, [])

  if (!isLoaded)
  {
    return (<>Loading...</>);
  }
  else if (error || data == null)
  {
    return (<>...</>);
  }
  else
  {
    return (
      <>
        <div className="flex-grow-1" style={{cursor: data && data.length > 0 ? 'pointer': 'default'}}  onClick={(e) => handleShow()}>
        <h3 className="mb-2">{!data ? 0 : data.length} Provider{!data || data.length === 0 || data.length > 1 ? "s" : ""} with Insufficent CMEs</h3>
        { data && data.length > 0 ?
          <>
            <p className="mb-2">Additional CMEs hours may be required</p>
            <div className="mb-0">
              <span className="text-muted" style={{fontSize:"0.9em"}}>24 months: </span>
              <Badge bg="" className="badge-soft-danger me-2">
                    &gt;= 50 hours
              </Badge>
              
            </div>
          </>
        : <><p className="mb-2">All providers have sufficient CME hours over the past 24 months!</p></>
        }
        </div>
        <div className="d-inline-block ms-3">
          <div className="">
            { data && data.length > 0 ?
              <BookOpen className="align-middle text-danger" />
            : <BookOpen className="align-middle" style={{color: '#3AAFA9'}} />
            }
          </div>
        </div>
        <InsufficientCmesModal show={show} onClose={handleClose} providerList={data}/>
      </>
    )
  }
}

const Statistics = () => {
  const { isH3Admin, authorities } = useContext(UserSettingsContext);

  return (
    <Row>
      <Col md="6" xl className="d-flex">
        <Card className="illustration flex-fill">
          <Card.Body className="p-0 d-flex flex-fill">
            <Row className="g-0 w-100">
              <Col xs="12" style={{marginBottom: "-30px"}}>
                <div className="illustration-text p-3 m-1">
                  <h4 className="illustration-text">
                    <Greeting />
                  </h4>
                  <p className="mb-0"><b>H3 Elevate</b></p>
                </div>
              </Col>
              <Col xs="8">
                <div className="illustration-text p-3 m-1">
                  <p><i>"Spring is nature's way of saying, 'Let's party!'" <br />- Robin Williams</i></p>
                </div>
              </Col>
              <Col xs={4} className="align-self-center text-end">
                <img
                  src={illustration}
                  alt="Customer Support"
                  className="img-fluid illustration-img"
                  style={{paddingRight: "5px"}}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      

      {(isH3Admin || authorities.includes("PROVIDER_DATA_HUB_VIEW") || authorities.includes("PROVIDER_DATA_HUB_EDIT") ||
        authorities.includes("PRACTICE_DATA_HUB_VIEW") || authorities.includes("PRACTICE_DATA_HUB_EDIT")
        ) ?
      <Col md="6" xl className="d-flex">
        <Card className="flex-fill">
          <Card.Body className=" py-4">
            <div className="d-flex align-items-start">
              <RenewalCount />
            </div>
          </Card.Body>
        </Card>
      </Col>
      : <></>
      }
      
      {(isH3Admin || authorities.includes("PROVIDER_DOCUMENT_VIEW") || authorities.includes("PROVIDER_DOCUMENT_EDIT") ||
        authorities.includes("PRACTICE_DOCUMENT_VIEW") || authorities.includes("PRACTICE_DOCUMENT_EDIT")
        ) ?
      <Col md="6" xl className="d-flex">
        <Card className="flex-fill">
          <Card.Body className=" py-4">
            <div className="d-flex align-items-start">
              <DocumentExpirationCount />
            </div>
          </Card.Body>
        </Card>
      </Col>
      : <></>
      }

    {(isH3Admin || authorities.includes("PROVIDER_CME_VIEW") || authorities.includes("PROVIDER_CME_EDIT")
        ) ?
      <Col md="6" xl className="d-flex">
        <Card className="flex-fill">
          <Card.Body className=" py-4">
            <div className="d-flex align-items-start">
              <InsufficientCmeCount />
            </div>
          </Card.Body>
        </Card>
      </Col>
      : <></>
    }
    </Row>
  );
};

export default Statistics;
