import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Button, Form } from "react-bootstrap";
import getOnboardingStages from "../../utils/onboardingStages";
import isFileSizeUnderLimit from "../../utils/fileUploadSize";
import Spinner from 'react-bootstrap/Spinner';
import { Flag } from "react-feather";

const { REACT_APP_BACKEND_URL } = process.env;


const OnboardingDocuments = ({stage, token, data}) => {
  let onboardingStages = getOnboardingStages(data.preferences);
  let colorPhotoRequired = data.preferences && data.preferences.colorPhotoRequired;
  let aclsRequired = data.preferences && data.preferences.aclsRequired;
  let cvRequired = data.preferences && data.preferences.cvRequired;
  let caseLogRequired = data.preferences && data.preferences.caseLogRequired;
  let cmeLogRequired = data.preferences && data.preferences.cmeLogRequired;

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        driversLicenseFilename: data.driversLicenseFilename == null ? "" : data.driversLicenseFilename,
        driversLicenseFile: null,
        driversLicenseBackFilename: data.driversLicenseBackFilename == null ? "" : data.driversLicenseBackFilename,
        driversLicenseBackFile: null,
        driversLicenseExpirationDate: data.driversLicenseExpirationDate == null ? "" : data.driversLicenseExpirationDate,
        aclsFilename: data.aclsFilename == null ? "" : data.aclsFilename,
        aclsFile: null,
        aclsExpirationDate: data.aclsExpirationDate == null ? "" : data.aclsExpirationDate,
        blsFilename: data.blsFilename == null ? "" : data.blsFilename,
        blsFile: null,
        blsExpirationDate: data.blsExpirationDate == null ? "" : data.blsExpirationDate,
        cvFile: null,
        cvFilename: data.cvFilename == null ? "" : data.cvFilename,
        cvPhotoFile: null,
        colorPhotoFilename: data.colorPhotoFilename == null ? "" : data.colorPhotoFilename,
        colorPhotoFile: null,
        ecfmgFilename: data.ecfmgFilename == null ? "" : data.ecfmgFilename,
        ecfmgFile: null,
        dd214Filename: data.dd214Filename == null ? "" : data.dd214Filename,
        dd214File: null,
        caseLogFilename: data.caseLogFilename == null ? "" : data.caseLogFilename,
        caseLogFile: null,
        cmeFilenames: data.cmeFilenames == null ? "" : data.cmeFilenames,
        cmeFiles: null,
        otherCertificationsFilenames: data.otherCertificationsFilenames == null ? "" : data.otherCertificationsFilenames,
        otherCertificationsFiles: null,
        
      }}
      validationSchema={Yup.object().shape({
        driversLicenseFilename: Yup.string(),
        driversLicenseFile: Yup.mixed().when("driversLicenseFilename", {
          is: value => !value || value === "",
          then: Yup.mixed().required("Government issued ID is required"),
          otherwise: Yup.mixed()
        })
        .test(
          "fileSize",
          "File size too large; max file size is 10 Mb",
          isFileSizeUnderLimit
        ),
        driversLicenseBackFilename: Yup.string(),
        driversLicenseBackFile: Yup.mixed()
        .test(
          "fileSize",
          "File size too large; max file size is 10 Mb",
          isFileSizeUnderLimit
        ),
        driversLicenseExpirationDate: Yup.date().required("Expiration date is required"),
        aclsFilename: Yup.string(),
        aclsFile: Yup.mixed().when("aclsFilename", {
          is: value => aclsRequired && (!value || value === ""),
          then: Yup.mixed().required("ACLS is required"),
          otherwise: Yup.mixed()
        })
        .test(
          "fileSize",
          "File size too large; max file size is 10 Mb",
          isFileSizeUnderLimit
        ),
        blsFile: Yup.mixed()
        .test(
          "fileSize",
          "File size too large; max file size is 10 Mb",
          isFileSizeUnderLimit
        ),
        cvFilename: Yup.string(),
        cvFile: Yup.mixed().when("cvFilename", {
          is: value => cvRequired && (!value || value === ""),
          then: Yup.mixed().required("CV is required"),
          otherwise: Yup.mixed()
        })
        .test(
          "fileSize",
          "File size too large; max file size is 10 Mb",
          isFileSizeUnderLimit
        ),
        colorPhotoFilename: Yup.string(),
        colorPhotoFile: Yup.mixed().when("colorPhotoFilename", {
          is: value => colorPhotoRequired && (!value || value === ""),
          then: Yup.mixed().required("Color photo is required"),
          otherwise: Yup.mixed()
        })
        .test(
          "fileSize",
          "File size too large; max file size is 10 Mb",
          isFileSizeUnderLimit
        ),
        ecfmgFile: Yup.mixed()
        .test(
          "fileSize",
          "File size too large; max file size is 10 Mb",
          isFileSizeUnderLimit
        ),
        dd214File: Yup.mixed()
        .test(
          "fileSize",
          "File size too large; max file size is 10 Mb",
          isFileSizeUnderLimit
        ),
        caseLogFilename: Yup.string(),
        caseLogFile: Yup.mixed().when("caseLogFilename", {
          is: value => caseLogRequired && (!value || value === ""),
          then: Yup.mixed().required("Case log is required"),
          otherwise: Yup.mixed()
        })
        .test(
          "fileSize",
          "File size too large; max file size is 10 Mb",
          isFileSizeUnderLimit
        ),
        cmeFiles: Yup.mixed()
        .test(
          "fileSize",
          "File size(s) too large; max total of 50MB",
          (files) => {
            var sum = 0;
            if (files) 
            {
              for (var i = 0; i < files.length; i++) {
                sum += files[i].size;
              }
              return sum <= (10485760 * 5);  /// Cap it at ~50MB total
              
            }
            return true;
          }
        ),
        otherCertificationsFiles: Yup.mixed()
        .test(
          "fileSize",
          "File size(s) too large; max total of 50MB",
          (files) => {
            var sum = 0;
            if (files) 
            {
              for (var i = 0; i < files.length; i++) {
                sum += files[i].size;
              }
              return sum <= (10485760 * 5);  /// Cap it at ~50MB total
              
            }
            return true;
          }
        ),
      })}
      onSubmit={async (values, { setErrors, setStatus, resetForm, setSubmitting }) => {
        try {
          var formData = new FormData();
          formData.append("data", new Blob([JSON.stringify(
            {
              driversLicenseExpirationDate: values.driversLicenseExpirationDate,
              aclsExpirationDate: values.aclsExpirationDate,
              blsExpirationDate: values.blsExpirationDate,
            })], {
              type: "application/json"
          }));

          if (values.driversLicenseFile)
          {
            formData.append("driversLicenseFile", values.driversLicenseFile);
          }
          if (values.driversLicenseBackFile)
          {
            formData.append("driversLicenseBackFile", values.driversLicenseBackFile);
          }
          if (values.aclsFile)
          {
            formData.append("aclsFile", values.aclsFile);
          }
          if (values.blsFile)
          {
            formData.append("blsFile", values.blsFile);
          }
          if (values.cvFile)
          {
            formData.append("cvFile", values.cvFile);
          }
          if (values.colorPhotoFile)
          {
            formData.append("colorPhotoFile", values.colorPhotoFile);
          }
          if (values.ecfmgFile)
          {
            formData.append("ecfmgFile", values.ecfmgFile);
          }
          if (values.dd214File)
          {
            formData.append("dd214File", values.dd214File);
          }
          if (values.caseLogFile)
          {
            formData.append("caseLogFile", values.caseLogFile);
          }
          if (values.cmeFiles)
          {
            for (var i = 0; i < values.cmeFiles.length; i++) 
            {
              formData.append("cmeFiles", values.cmeFiles[i]);
            }
          }
          if (values.otherCertificationsFiles)
          {
            for (var i = 0; i < values.otherCertificationsFiles.length; i++) 
            {
              formData.append("otherCertificationsFiles", values.otherCertificationsFiles[i]);
            }
          }

          const requestMetadata = {
            method: 'POST',
            body: formData
          };

          const res = await fetch(REACT_APP_BACKEND_URL + "onboarding/" + stage + "/" + token, requestMetadata); 
          if (res.status >= 200 && res.status <= 299)
          {
            setStatus({success: true})

            window.location = "/onboarding/" + onboardingStages[onboardingStages.indexOf(stage) + 1] + "/" + token
          }
          else
          {
            throw Error(res.statusText);
          }
        } catch (error) {
          const message = "Something went wrong; please try again";

          setStatus({ success: false });
          alert(message);
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        status,
        touched,
        values,
        getFieldProps,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label style={{fontWeight: "bold"}}>Documents</Form.Label>
            <br /><br />
            <Form.Label>Government Issued ID (Front)*</Form.Label>
            <br />
            {values.driversLicenseFilename && 
              <Form.Label>(Current: <span style={{wordBreak: "break-all"}}>{values.driversLicenseFilename}</span>)</Form.Label>
            }
            <Form.Control
              type="hidden"
              {...getFieldProps("driversLicenseFilename")}
            />
            <Form.Control
              type="file"
              name="driversLicenseFile"
              onBlur={handleBlur}
              onChange={(event) => {
                const file = event.currentTarget.files.length > 0 ? event.currentTarget.files[0] : null;
                setFieldValue("driversLicenseFile", file);
              }}
              isInvalid={Boolean(touched.driversLicenseFile && errors.driversLicenseFile)}
            />
            {!!touched.driversLicenseFile && (
              <Form.Control.Feedback type="invalid">
                {errors.driversLicenseFile}
              </Form.Control.Feedback>
            )}
            <br />
            <Form.Label>Government Issued ID (Back)
            <br /><span style={{fontSize: '0.9em', fontWeight: "normal"}}><Flag style={{height: "16px", width: "16px", color: "#2B7A78"}} /> If applicable</span>
            </Form.Label>
            <br />
            {values.driversLicenseBackFilename && 
              <Form.Label>(Current: <span style={{wordBreak: "break-all"}}>{values.driversLicenseBackFilename}</span>)</Form.Label>
            }
            <Form.Control
              type="hidden"
              {...getFieldProps("driversLicenseBackFilename")}
            />
            <Form.Control
              type="file"
              name="driversLicenseBackFile"
              onBlur={handleBlur}
              onChange={(event) => {
                const file = event.currentTarget.files.length > 0 ? event.currentTarget.files[0] : null;
                setFieldValue("driversLicenseBackFile", file);
              }}
              isInvalid={Boolean(touched.driversLicenseBackFile && errors.driversLicenseBackFile)}
            />
            {!!touched.driversLicenseBackFile && (
              <Form.Control.Feedback type="invalid">
                {errors.driversLicenseBackFile}
              </Form.Control.Feedback>
            )}
            <br />
            <Form.Label style={{fontSize: "0.9em"}}>Expiration Date*</Form.Label>
            <Form.Control
              type="date"
              {...getFieldProps("driversLicenseExpirationDate")} 
              isInvalid={Boolean(touched.driversLicenseExpirationDate && errors.driversLicenseExpirationDate)}
            />
            {!!touched.driversLicenseExpirationDate && (
            <Form.Control.Feedback type="invalid">
              {errors.driversLicenseExpirationDate}
            </Form.Control.Feedback>
            )}
          </Form.Group>
          <br />
          <Form.Group className="mb-3">
            <Form.Label>CV{cvRequired && "*"}</Form.Label>
            <br />
            {values.cvFilename && 
              <Form.Label>(Current: <span style={{wordBreak: "break-all"}}>{values.cvFilename}</span>)</Form.Label>
            }
            <Form.Control
              type="hidden"
              {...getFieldProps("cvFilename")}
            />
            <Form.Control
              type="file"
              name="cvFile"
              onBlur={handleBlur}
              onChange={(event) => {
                const file = event.currentTarget.files.length > 0 ? event.currentTarget.files[0] : null;
                setFieldValue("cvFile", file);
              }}
              isInvalid={Boolean(touched.cvFile && errors.cvFile)}
            />
            {!!touched.cvFile && (
              <Form.Control.Feedback type="invalid">
                {errors.cvFile}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <br />
          <Form.Group className="mb-3">
            <Form.Label>Color Photo{colorPhotoRequired && "*"}</Form.Label>
            <br />
            {values.colorPhotoFilename && 
              <Form.Label>(Current: <span style={{wordBreak: "break-all"}}>{values.colorPhotoFilename}</span>)</Form.Label>
            }
            <Form.Control
              type="hidden"
              {...getFieldProps("colorPhotoFilename")}
            />
            <Form.Control
              type="file"
              accept="image/*,application/pdf"
              name="colorPhotoFile"
              onBlur={handleBlur}
              onChange={(event) => {
                const file = event.currentTarget.files.length > 0 ? event.currentTarget.files[0] : null;
                setFieldValue("colorPhotoFile", file);
              }}
              isInvalid={Boolean(touched.colorPhotoFile && errors.colorPhotoFile)}
            />
            {!!touched.colorPhotoFile && (
              <Form.Control.Feedback type="invalid">
                {errors.colorPhotoFile}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <br />
          <Form.Group className="mb-3">
            <Form.Label>ACLS{aclsRequired && "*"}</Form.Label>
            <br />
            {values.aclsFilename && 
              <Form.Label>(Current: <span style={{wordBreak: "break-all"}}>{values.aclsFilename}</span>)</Form.Label>
            }
            <Form.Control
              type="hidden"
              {...getFieldProps("aclsFilename")}
            />
            <Form.Control
              type="file"
              name="aclsFile"
              onBlur={handleBlur}
              onChange={(event) => {
                const file = event.currentTarget.files.length > 0 ? event.currentTarget.files[0] : null;
                setFieldValue("aclsFile", file);
              }}
              isInvalid={Boolean(touched.aclsFile && errors.aclsFile)}
            />
            {!!touched.aclsFile && (
              <Form.Control.Feedback type="invalid">
                {errors.aclsFile}
              </Form.Control.Feedback>
            )}
            <br />
            <Form.Label style={{fontSize: "0.9em"}}>Expiration Date</Form.Label>
            <Form.Control
              type="date"
              {...getFieldProps("aclsExpirationDate")} 
              isInvalid={Boolean(touched.aclsExpirationDate && errors.aclsExpirationDate)}
            />
            {!!touched.aclsExpirationDate && (
            <Form.Control.Feedback type="invalid">
              {errors.aclsExpirationDate}
            </Form.Control.Feedback>
            )}
          </Form.Group>
          <br />
          <Form.Group className="mb-3">
            <Form.Label style={{fontWeight: "bold"}}>BLS
            <br /><span style={{fontSize: '0.9em', fontWeight: "normal"}}><Flag style={{height: "16px", width: "16px", color: "#2B7A78"}} /> APPs only</span>
            </Form.Label>
            <br />
            {values.blsFilename && 
              <Form.Label>(Current: <span style={{wordBreak: "break-all"}}>{values.blsFilename}</span>)</Form.Label>
            }
            <Form.Control
              type="hidden"
              {...getFieldProps("blsFilename")}
            />
            <Form.Control
              type="file"
              name="blsFile"
              onBlur={handleBlur}
              onChange={(event) => {
                const file = event.currentTarget.files.length > 0 ? event.currentTarget.files[0] : null;
                setFieldValue("blsFile", file);
              }}
              isInvalid={Boolean(touched.blsFile && errors.blsFile)}
            />
            {!!touched.blsFile && (
              <Form.Control.Feedback type="invalid">
                {errors.blsFile}
              </Form.Control.Feedback>
            )}
            <br />
            <Form.Label style={{fontSize: "0.9em"}}>Expiration Date</Form.Label>
            <Form.Control
              type="date"
              {...getFieldProps("blsExpirationDate")} 
              isInvalid={Boolean(touched.blsExpirationDate && errors.blsExpirationDate)}
            />
            {!!touched.blsExpirationDate && (
            <Form.Control.Feedback type="invalid">
              {errors.blsExpirationDate}
            </Form.Control.Feedback>
            )}
          </Form.Group>
          <br />
          <Form.Group className="mb-3">
            <Form.Label style={{fontWeight: "bold"}}>ECFMG Certificate
            <br /><span style={{fontSize: '0.9em', fontWeight: "normal"}}><Flag style={{height: "16px", width: "16px", color: "#2B7A78"}} /> If foreign medical graduate</span>
            </Form.Label>
            <br />
            {values.ecfmgFilename && 
              <Form.Label>(Current: <span style={{wordBreak: "break-all"}}>{values.ecfmgFilename}</span>)</Form.Label>
            }
            <Form.Control
              type="hidden"
              {...getFieldProps("ecfmgFilename")}
            />
            <Form.Control
              type="file"
              name="ecfmgFile"
              onBlur={handleBlur}
              onChange={(event) => {
                const file = event.currentTarget.files.length > 0 ? event.currentTarget.files[0] : null;
                setFieldValue("ecfmgFile", file);
              }}
              isInvalid={Boolean(touched.ecfmgFile && errors.ecfmgFile)}
            />
            {!!touched.ecfmgFile && (
              <Form.Control.Feedback type="invalid">
                {errors.ecfmgFile}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <br />
          <Form.Group className="mb-3">
            <Form.Label style={{fontWeight: "bold"}}>DD214
            <br /><span style={{fontSize: '0.9em', fontWeight: "normal"}}><Flag style={{height: "16px", width: "16px", color: "#2B7A78"}} /> If prior military</span>
            </Form.Label>
            <br />
            {values.dd214Filename && 
              <Form.Label>(Current: <span style={{wordBreak: "break-all"}}>{values.dd214Filename}</span>)</Form.Label>
            }
            <Form.Control
              type="hidden"
              {...getFieldProps("dd214Filename")}
            />
            <Form.Control
              type="file"
              name="dd214File"
              onBlur={handleBlur}
              onChange={(event) => {
                const file = event.currentTarget.files.length > 0 ? event.currentTarget.files[0] : null;
                setFieldValue("dd214File", file);
              }}
              isInvalid={Boolean(touched.dd214File && errors.dd214File)}
            />
            {!!touched.dd214File && (
              <Form.Control.Feedback type="invalid">
                {errors.dd214File}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <br />
          <Form.Group className="mb-3">
            <Form.Label style={{fontWeight: "bold"}}>Case Log / Procedure Count{caseLogRequired && "*"}
            <br /><span style={{fontSize: '0.9em', fontWeight: "normal"}}><Flag style={{height: "16px", width: "16px", color: "#2B7A78"}} /> For past two years, including CPT codes, descriptions, dates and counts.  No patient information.</span>
            </Form.Label>
            <br />
            {values.caseLogFilename && 
              <Form.Label>(Current: <span style={{wordBreak: "break-all"}}>{values.caseLogFilename}</span>)</Form.Label>
            }
            <Form.Control
              type="hidden"
              {...getFieldProps("caseLogFilename")}
            />
            <Form.Control
              type="file"
              name="caseLogFile"
              onBlur={handleBlur}
              onChange={(event) => {
                const file = event.currentTarget.files.length > 0 ? event.currentTarget.files[0] : null;
                setFieldValue("caseLogFile", file);
              }}
              isInvalid={Boolean(touched.caseLogFile && errors.caseLogFile)}
            />
            {!!touched.caseLogFile && (
              <Form.Control.Feedback type="invalid">
                {errors.caseLogFile}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <br />
          <Form.Group className="mb-3">
            <Form.Label>CME Log and/or Certificates
            <br /><span style={{fontSize: '0.9em', fontWeight: "normal"}}><Flag style={{height: "16px", width: "16px", color: "#2B7A78"}} /> Required if more than one year past graduation</span>
            </Form.Label>
            <br />
            {values.cmeFilenames && values.cmeFilenames.length > 0 &&
              <Form.Label>(Current: <span style={{wordBreak: "break-all"}}>{values.cmeFilenames.join(", ")}</span>)</Form.Label>
            }
            <Form.Control
              type="hidden"
              {...getFieldProps("cmeFilenames")}
            />
            <Form.Control
              multiple
              type="file"
              name="cmeFiles"
              onBlur={handleBlur}
              onChange={(event) => {
                setFieldValue("cmeFiles", event.currentTarget.files);
              }}
              isInvalid={Boolean(touched.cmeFiles && errors.cmeFiles)}
            />
            {!!touched.cmeFiles && (
              <Form.Control.Feedback type="invalid">
                {errors.cmeFiles}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <br />
          <Form.Group className="mb-3">
            <Form.Label>Other Certifications</Form.Label>
            <br />
            {values.otherCertificationsFilenames && values.otherCertificationsFilenames.length > 0 &&
              <Form.Label>(Current: <span style={{wordBreak: "break-all"}}>{values.otherCertificationsFilenames.join(", ")}</span>)</Form.Label>
            }
            <Form.Control
              type="hidden"
              {...getFieldProps("otherCertificationsFilenames")}
            />
            <Form.Control
              multiple
              type="file"
              name="otherCertificationsFiles"
              onBlur={handleBlur}
              onChange={(event) => {
                setFieldValue("otherCertificationsFiles", event.currentTarget.files);
              }}
              isInvalid={Boolean(touched.otherCertificationsFiles && errors.otherCertificationsFiles)}
            />
            {!!touched.otherCertificationsFiles && (
              <Form.Control.Feedback type="invalid">
                {errors.otherCertificationsFiles}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <br />
          {isSubmitting ?
          <div className="text-center">
             <br />
            <Spinner size="sm" animation="border" />
          </div>
          :
          <>
          <div className="text-center mt-3 float-start">
            <Button size="lg" className="btn-secondary" onClick={() => {window.location = "/onboarding/" + onboardingStages[onboardingStages.indexOf(stage) - 1] + "/" + token}}>Back</Button>
          </div>
          <div id="submitButton" className="text-center mt-3 float-end">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isSubmitting}
            >
              Save and Continue
            </Button>
          </div>
          </>
          }
          <br /><br /><br />
          {
            Object.keys(errors).length > 0 && Object.keys(touched).length > 0 &&
            <div className="text-center mt-3"><span className="invalid-feedback" style={{display: "inline-block"}}>Please correct the errors above before completing the submission</span></div>
          }
        </Form>
      )}
    </Formik>
  );
}

export default OnboardingDocuments;
