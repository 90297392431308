import React from "react";
import * as Yup from "yup";
import { useFormikContext, Formik } from "formik";
import { Button, Form } from "react-bootstrap";
import { Trash2, Flag} from "react-feather";
import getOnboardingStages from "../../utils/onboardingStages";
import isFileSizeUnderLimit from "../../utils/fileUploadSize";
import Spinner from 'react-bootstrap/Spinner';


const { REACT_APP_BACKEND_URL } = process.env;



const AddBoardCertificationButton = () => {
  const { values, setFieldValue } = useFormikContext();

  const handleAddClicked = (e) =>
  {
      e.preventDefault();
      setFieldValue("boardCertifications", [
        ...values.boardCertifications,
        {certification: "", notes: "", effectiveDate: "", expirationDate: "", document: null, documentFilename: ""},
      ]);
  }

  return (
    <Button onClick={(e) => handleAddClicked(e)} size="sm">+ Add Certification</Button>
  );
}

const RemoveBoardCertificationButton = ({index}) => {
  const { values, setFieldValue } = useFormikContext();

  const handleRemoveClicked = (e) =>
  {
      e.preventDefault();
      setFieldValue("boardCertifications", values.boardCertifications.filter((_, i) => i !== index));
  }

  return (
    <Trash2 style={{'height': '12px', 'width': '12px'}} className="float-end" cursor="pointer" onClick={(e) => {handleRemoveClicked(e)}}/>
  );
}


const OnboardingBoardCertifications = ({stage, token, data}) => {
  var initialValues = {boardCertifications:[]}

  if (data && data.boardCertifications)
  {
    for (var i = 0; i < data.boardCertifications.length; i++)
    {
      if (data.boardCertifications[i].certification == null)
      {
        data.boardCertifications[i].certification = "";
      }
      if (data.boardCertifications[i].notes == null)
      {
        data.boardCertifications[i].notes = "";
      }
      if (data.boardCertifications[i].effectiveDate == null)
      {
        data.boardCertifications[i].effectiveDate = "";
      }
      if (data.boardCertifications[i].expirationDate == null)
      {
        data.boardCertifications[i].expirationDate = "";
      }
      if (data.boardCertifications[i].documentFilename == null)
      {
        data.boardCertifications[i].documentFilename = "";
      }

      data.boardCertifications[i].document = null;

      initialValues.boardCertifications.push(data.boardCertifications[i]);
    }
  }

  let required = data.preferences && data.preferences.boardCertificationsRequired;

  if (required)
  {
    for (i=initialValues.boardCertifications.length; i < 1; i++)
    {
      initialValues.boardCertifications.push({certification: "", notes: "", effectiveDate: "", expirationDate: "", document: null, documentFilename: ""});
    }
  }

  let onboardingStages = getOnboardingStages(data.preferences);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        boardCertifications: Yup.array().of(
          Yup.object().shape({
            certification: Yup.string().max(255).required("Certification is required"),
            notes: Yup.string().max(255).required("Certifying number is required"),
            effectiveDate: Yup.date().required("Effective date is required"),
            expirationDate: Yup.date(),
            documentFilename: Yup.string(),
            document: Yup.mixed().when("documentFilename", {
              is: value => !value || value === "",
              then: Yup.mixed().required("Certification document is required"),
              otherwise: Yup.mixed()
            })
            .test(
              "fileSize",
              "File size too large; max file size is 10 Mb",
              isFileSizeUnderLimit
            ),
          })
        )
      })}
      onSubmit={async (values, { setErrors, setStatus, resetForm, setSubmitting }) => {
        try 
        {
          for (var i = 0; i < values.boardCertifications.length; i++)
          {
            // Set the document filename to the file we're sending so we can link the two up on the backend; preserve the old, if any, so we can delete
            values.boardCertifications[i].oldDocumentFilename = values.boardCertifications[i].documentFilename;
            if (values.boardCertifications[i].document !== null)
            {
              values.boardCertifications[i].documentFilename = values.boardCertifications[i].document.name;
            }
          }

          var formData = new FormData();
          formData.append("data", new Blob([JSON.stringify(
            {
              boardCertifications: values.boardCertifications
            })], {
              type: "application/json"
          }));

          for (i = 0; i < values.boardCertifications.length; i++)
          {
            if (values.boardCertifications[i].document !== null)
            {
              formData.append("files", values.boardCertifications[i].document);
            }
          }

          const requestMetadata = {
            method: 'POST',
            body: formData
          };

          const res = await fetch(REACT_APP_BACKEND_URL + "onboarding/" + stage + "/" + token, requestMetadata);

          if (res.status >= 200 && res.status <= 299)
          {
            setStatus({success: true})

            window.location = "/onboarding/" + onboardingStages[onboardingStages.indexOf(stage) + 1] + "/" + token
          }
          else
          {
            throw Error(res.statusText);
          }
        } catch (error) {
          const message = "Something went wrong; please try again";

          setStatus({ success: false });
          alert(message);
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        status,
        touched,
        values,
        getFieldProps,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label style={{fontWeight: "bold"}}>Board Certifications{required && "*"}
            <br /><span style={{fontSize: '0.9em', fontWeight: "normal"}}><Flag style={{height: "16px", width: "16px", color: "#2B7A78"}} /> Please only include your <i>current</i> board certification(s).</span>
            </Form.Label><br />
            <br />
            {values.boardCertifications.map((boardCertification, index) => (
                <div key={index}>
                <i>#{index +1}</i>
                {(!required || index > 0) && <RemoveBoardCertificationButton index={index} />}
                
                <br />
                <Form.Control
                  placeholder="Board Name & Certification"
                  {...getFieldProps(`boardCertifications[${index}].certification`)} 
                  isInvalid={Boolean(touched.boardCertifications?.[index]?.certification && errors.boardCertifications?.[index]?.certification)}
                />
                {!!touched.boardCertifications?.[index]?.certification && (
                  <Form.Control.Feedback type="invalid">
                    {errors.boardCertifications?.[index]?.certification}
                  </Form.Control.Feedback>
                )}
                <br />
                <Form.Control
                  placeholder="Certification Number"
                  {...getFieldProps(`boardCertifications[${index}].notes`)}
                  isInvalid={Boolean(touched.boardCertifications?.[index]?.notes && errors.boardCertifications?.[index]?.notes)}
                />
                {!!touched.boardCertifications?.[index]?.notes && (
                  <Form.Control.Feedback type="invalid">
                    {errors.boardCertifications?.[index]?.notes}
                  </Form.Control.Feedback>
                )}
                <br />
                <Form.Label style={{fontSize: "0.9em"}}>Effective Date</Form.Label>
                <Form.Control
                  type="date"
                  {...getFieldProps(`boardCertifications[${index}].effectiveDate`)} 
                  isInvalid={Boolean(touched.boardCertifications?.[index]?.effectiveDate && errors.boardCertifications?.[index]?.effectiveDate)}
                />
                {!!touched.boardCertifications?.[index]?.effectiveDate && (
                  <Form.Control.Feedback type="invalid">
                    {errors.boardCertifications?.[index]?.effectiveDate}
                  </Form.Control.Feedback>
                )}
                <br />
                <Form.Label style={{fontSize: "0.9em"}}>Expiration Date</Form.Label>
                <Form.Control
                  type="date"
                  {...getFieldProps(`boardCertifications[${index}].expirationDate`)} 
                  isInvalid={Boolean(touched.boardCertifications?.[index]?.expirationDate && errors.boardCertifications?.[index]?.expirationDate)}
                />
                {!!touched.boardCertifications?.[index]?.expirationDate && (
                  <Form.Control.Feedback type="invalid">
                    {errors.boardCertifications?.[index]?.expirationDate}
                  </Form.Control.Feedback>
                )}
                <br />
                {values.boardCertifications?.[index]?.documentFilename && 
                <Form.Label>(Current: <span style={{wordBreak: "break-all"}}>{values.boardCertifications?.[index]?.documentFilename}</span>)</Form.Label>
                }
                <Form.Control
                  type="hidden"
                  {...getFieldProps(`boardCertifications[${index}].documentFilename`)}
                />
                <Form.Control
                  type="file"
                  name={`boardCertifications[${index}].document`}
                  onBlur={handleBlur}
                  onChange={(event) => {
                    const file = event.currentTarget.files.length > 0 ? event.currentTarget.files[0] : null;
                    setFieldValue(`boardCertifications[${index}].document`, file);
                  }}
                  isInvalid={Boolean(touched.boardCertifications?.[index]?.document && errors.boardCertifications?.[index]?.document)}
                />
                {!!touched.boardCertifications?.[index]?.document && (
                  <Form.Control.Feedback type="invalid">
                    {errors.boardCertifications?.[index]?.document}
                  </Form.Control.Feedback>
                )}
                <br />
                </div>
            ))}
            <AddBoardCertificationButton />
          </Form.Group>
          <br />
          {isSubmitting ?
          <div className="text-center">
             <br />
            <Spinner size="sm" animation="border" />
          </div>
          :
          <>
          <div className="text-center mt-3 float-start">
            <Button size="lg" className="btn-secondary" onClick={() => {window.location = "/onboarding/" + onboardingStages[onboardingStages.indexOf(stage) - 1] + "/" + token}}>Back</Button>
          </div>
          <div id="submitButton" className="text-center mt-3 float-end">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isSubmitting}  // Don't check touched, otherwise you end up with this button disabled for, e.g. a peer reference first name missing that has never been touched, so it's not in red; let them click this to touch everything
            >
              Save and Continue
            </Button>
          </div>
          </>
          }
          <br /><br /><br />
          {
            Object.keys(errors).length > 0 && Object.keys(touched).length > 0 &&
            <div className="text-center mt-3"><span className="invalid-feedback" style={{display: "inline-block"}}>Please correct the errors above before moving forward</span></div>
          }
        </Form>
      )}
    </Formik>
  );
}

export default OnboardingBoardCertifications;
