const rawImmunizationTypes =[
    {"value":"", "label":""},
    {"value": "COVID", "label":"Covid-19"},
    {"value": "FLU", "label":"Influenza"},
    {"value": "HEP_A", "label":"Hepatitis A"},
    {"value": "HEP_B", "label":"Hepatitis B"},
    {"value": "MMR", "label":"MMR"},
    {"value": "OTHER", "label":"Other"},
    {"value":"TETANUS", "label":"Tetanus"},
    {"value":"TB", "label":"Tuberculosis"},
    {"value":"TB_SCREENING", "label":"Tuberculosis Screening"},
    {"value": "VARICELLA", "label":"Varicella"}
  ];

export default rawImmunizationTypes;