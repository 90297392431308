import React, { useState, useContext, useEffect } from "react";
import { Button, Modal, Table, Form, Spinner } from "react-bootstrap";

import NotyfContext from "../contexts/NotyfContext";

import { UserSettingsContext } from "../contexts/UserSettingsContext";

import authenticatedFetch from "../utils/fetch"

import TaskAssigneeSelect from "./TaskAssigneeSelect";
import rawTaskStatuses from "./taskStatuses";
import ActivityRow from "./ActivityRow";

import NextActionDatePicker from "./NextActionDatePicker";


const PracticeSelect = ({taskId, selectedPracticeId, disabled, handlePracticeChanged}) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState([]);
  
    useEffect(() => {
      authenticatedFetch({
        path: "practice/revenueteam",
        additionalHeaders: {"taskId": taskId},
        successHandler: function(result) {
          setIsLoaded(true);
          setData(result);
        },
        errorHandler: function(error) {
          setIsLoaded(true);
          setError(error);
        }
      });
    }, [taskId])
  
    if (!isLoaded)
    {
      return (<Form.Select disabled={true}><option value={0} key={0}>Loading...</option></Form.Select>);
    }
    else if (error || !data)
    {
      return (<Form.Select disabled={true}><option value={0} key={0}>Error... Please reload the page...</option></Form.Select>);
    }
    else
    {
      let practiceOptionList = [<option value={0} key={0}>Select a practice...</option>];
      practiceOptionList.push(data.map((practice, i) => {
        return (
          <option value={practice.id} key={practice.id}>{practice.name}</option>
        )
      }, this));
  
      return (
        <Form.Select disabled={disabled} onChange={(e) => {handlePracticeChanged(e.target.value, e)}} value={selectedPracticeId}>{practiceOptionList}</Form.Select>
      )
    }
  }
  
  const CarrierSelect = ({ taskId, practiceId, disabled, selectedCarrierId, handleCarrierChanged}) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState([]);
  
    useEffect(() => {
      if (practiceId > 0)
      {
        authenticatedFetch({
          path: "carrier/practice/" + practiceId,
          additionalHeaders: {"taskId": taskId},
          successHandler: function(result) {
            setIsLoaded(true);
            setData(result);
          },
          errorHandler: function(error) {
            setIsLoaded(true);
            setError(error);
          }
        });
      }
      else
      {
        setData([]);
      }
    }, [practiceId, taskId])
  
    if (practiceId == null || practiceId <= 0)
    {
      return (<Form.Select disabled={true}><option value={0} key={0}>Please first select a practice...</option></Form.Select>);
    }
    else if (!isLoaded)
    {
      return (<Form.Select disabled={true}><option value={0} key={0}>Loading...</option></Form.Select>);
    }
    else if (error || !data)
    {
      return (<Form.Select disabled={true}><option value={0} key={0}>Error... Please reload the page...</option></Form.Select>);
    }
    else
    {
      let carrierOptionList = [<option value={0} key={0}>Select a carrier...</option>];
      carrierOptionList.push(data.map((carrier, i) => {
        return (
          <option value={carrier.id} key={carrier.id}>{carrier.name}</option>
        )
      }, this));
  
      return (
        <Form.Select disabled={disabled} onChange={(e) => {handleCarrierChanged(e.target.value, e)}} value={selectedCarrierId}>{carrierOptionList}</Form.Select>
      )
    }
  }
  
  const ProviderSelect = ({ taskId, practiceId, disabled, selectedProviderId, handleProviderChanged}) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
      if (practiceId > 0)
      {
        authenticatedFetch({
          path: "provider/practice/" + practiceId,
          additionalHeaders: {"taskId": taskId},
          successHandler: function(result) {
            setIsLoaded(true);
            setData(result);
          },
          errorHandler: function(error) {
            setIsLoaded(true);
            setError(error);
          }
        });
      }
      else
      {
        setData([]);
      }
    }, [practiceId, taskId])
  
    if (practiceId == null || practiceId <= 0)
    {
      return (<Form.Select disabled={true}><option value={0} key={0}>Please first select a practice...</option></Form.Select>);
    }
    else if (!isLoaded)
    {
      return (<Form.Select disabled={true}><option value={0} key={0}>Loading...</option></Form.Select>);
    }
    else if (error || !data)
    {
      return (<Form.Select disabled={true}><option value={0} key={0}>Error... Please reload the page...</option></Form.Select>);
    }
    else
    {
      let providerOptionList = [<option value={0} key={0}>Select a provider...</option>];
      providerOptionList.push(data.map((provider, i) => {
        return (
          <option value={provider.id} key={provider.id}>{provider.lastName}, {provider.firstName} {provider.middleName}</option>
        )
      }, this));
  
      return (
        <Form.Select disabled={disabled} onChange={(e) => {handleProviderChanged(e.target.value, e)}} value={selectedProviderId}>{providerOptionList}</Form.Select>
      )
    }
  }
  
const HubSelect = ({ taskId, providerId, practiceId, disabled, selectedHubId, handleHubChanged}) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState([]);
  
    useEffect(() => {
      if (providerId > 0)
      {
        authenticatedFetch({
          path: "hub/provider/" + providerId,
          additionalHeaders: {"taskId": taskId},
          successHandler: function(result) {
            setIsLoaded(true);
            setData(result);
          },
          errorHandler: function(error) {
            setIsLoaded(true);
            setError(error);
          }
        });
      }
      else if (practiceId > 0)
      {
        authenticatedFetch({
          path: "hub/practice/" + practiceId,
          additionalHeaders: {"taskId": taskId},
          successHandler: function(result) {
            setIsLoaded(true);
            setData(result);
          },
          errorHandler: function(error) {
            setIsLoaded(true);
            setError(error);
          }
        });
      }
      else
      {
        setData([]);
      }
    }, [providerId, practiceId, taskId])
  
    if ((providerId == null || providerId <= 0) && (practiceId == null || practiceId <= 0))
    {
      return (<Form.Select disabled={true}><option value={0} key={0}>Please first select a provider or practice...</option></Form.Select>);
    }
    else if (!isLoaded)
    {
      return (<Form.Select disabled={true}><option value={0} key={0}>Loading...</option></Form.Select>);
    }
    else if (error || !data)
    {
      return (<Form.Select disabled={true}><option value={0} key={0}>Error... Please reload the page...</option></Form.Select>);
    }
    else
    {
      let hubOptionList = [<option value={0} key={0}>Select a data hub ({providerId != null && providerId > 0 ? "provider" : "practice"})...</option>];
      hubOptionList.push(data.map((hub, i) => {
        return (
          <option value={hub.id} key={hub.id}>{hub.name}</option>
        )
      }, this));
  
      return (
        <Form.Select disabled={disabled} onChange={(e) => {handleHubChanged(e.target.value, e)}} value={selectedHubId}>{hubOptionList}</Form.Select>
      )
    }
}

const FacilitySelect = ({ taskId, practiceId, disabled, selectedFacilityId, handleFacilityChanged}) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState([]);
  
    useEffect(() => {
      if (practiceId > 0)
      {
        authenticatedFetch({
          path: "facility/practice/" + practiceId,
          additionalHeaders: {"taskId": taskId},
          successHandler: function(result) {
            setIsLoaded(true);
            setData(result);
          },
          errorHandler: function(error) {
            setIsLoaded(true);
            setError(error);
          }
        });
      }
      else
      {
        setData([]);
      }
    }, [practiceId, taskId])
  
    if (practiceId == null || practiceId <= 0)
    {
      return (<Form.Select disabled={true}><option value={0} key={0}>Please first select a practice...</option></Form.Select>);
    }
    else if (!isLoaded)
    {
      return (<Form.Select disabled={true}><option value={0} key={0}>Loading...</option></Form.Select>);
    }
    else if (error || !data)
    {
      return (<Form.Select disabled={true}><option value={0} key={0}>Error... Please reload the page...</option></Form.Select>);
    }
    else
    {
      let facilityOptionList = [<option value={0} key={0}>Select a location...</option>];
      facilityOptionList.push(data.map((facility, i) => {
        return (
          <option value={facility.id} key={facility.id}>{facility.name}</option>
        )
      }, this));
  
      return (
        <Form.Select disabled={disabled} onChange={(e) => {handleFacilityChanged(e.target.value, e)}} value={selectedFacilityId}>{facilityOptionList}</Form.Select>
      )
    }
}

const NewAttestationDateModal = ({ show, onClose, newAttestationDate, handleNewAttestationDateChanged, handleSkip}) => {
  return (
      <Modal show={show} onHide={onClose} centered>
        <Modal.Header closeButton style={{backgroundColor: '#DEF2F1'}}>
          <Modal.Title>
            <span style={{fontSize: "0.9em", fontWeight: "normal"}}>Specify Next Attestation Date</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table> 
            <tbody>
              <tr>
                  <td>Next Attestation Date:</td>
                  <td><Form.Control onChange={(e) => {handleNewAttestationDateChanged(e.target.value)}} type="date" value={newAttestationDate} placeholder="New attestation date" /></td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer style={{backgroundColor: '#DEF2F1'}}>
            <Button className="me-auto btn-secondary" onClick={handleSkip} size="sm">Skip</Button>
            <Button onClick={onClose}>Complete Task</Button>
        </Modal.Footer>
      </Modal>
  );
}

const NewExpirationDateDateModal = ({ show, onClose, newExpirationDate, handleNewExpirationDateChanged, showNewEffectiveDate, newEffectiveDate, handleNewEffectiveDateChanged, showSendEmailNotification, sendEmailNotification, handleSendEmailNotificationChanged, handleSkip}) => {
  const onEffectiveDateChanged = (value, e) => {
    handleNewEffectiveDateChanged(value, e);

    if (!newExpirationDate || newExpirationDate === "")
    {
      var parts = value.split("-");
  
      // If they just started keying in the date, it will come over as, e.g. 0002, and we don't want to interpret that as a real date and then set it as the value; just set this raw string value, e.g. 0002-12-21
      if (parseInt(parts[0]) > 1970)
      {
        var aYearFromNow = new Date(value);
        aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 3);
        aYearFromNow.setDate(aYearFromNow.getDate() - 1);
        handleNewExpirationDateChanged(aYearFromNow.toISOString().split('T')[0], e);
      }
    }
  }

  return (
      <Modal show={show} onHide={onClose} centered>
        <Modal.Header closeButton style={{backgroundColor: '#DEF2F1'}}>
          <Modal.Title>
            <span style={{fontSize: "0.9em", fontWeight: "normal"}}>{showNewEffectiveDate ? "Specify Effective & Expiration Dates" : "Specify Next Expiration Date"}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table> 
            <tbody>
              {showNewEffectiveDate &&
              <tr>
                  <td>Effective Date:</td>
                  <td><Form.Control onChange={(e) => {onEffectiveDateChanged(e.target.value, e)}} type="date" value={newEffectiveDate} placeholder="Next effective date" /></td>
              </tr>
              }
              <tr>
                  <td>{showNewEffectiveDate ? "" : "Next"} Expiration Date:</td>
                  <td><Form.Control onChange={(e) => {handleNewExpirationDateChanged(e.target.value, e)}} type="date" value={newExpirationDate} placeholder="Next expiration date" /></td>
              </tr>
              {showSendEmailNotification &&
              <tr>
                  <td>Send Email Notification:</td>
                  <td><Form.Check checked={sendEmailNotification} onChange={(e) => {handleSendEmailNotificationChanged(e.target.checked)}} /></td>
              </tr>
              }
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer style={{backgroundColor: '#DEF2F1'}}>
            <Button className="me-auto btn-secondary" onClick={handleSkip} size="sm">Skip</Button>
            <Button onClick={onClose}>Complete Task</Button>
        </Modal.Footer>
      </Modal>
  );
}

const TaskModal = ({ id, category, practiceId, carrierId, providerId, hubId, facilityId, other, referenceNumber, assignees, status, due, deadline, progress, archived, activityList, show, onCancel, onSave, onDelete }) => {
    const [localId] = useState(id);
    const [localStatus, setLocalStatus] = useState(status);
    const [localCategory, setLocalCategory] = useState(category);
    const [localPracticeId, setLocalPracticeId] = useState(practiceId);
    const [localCarrierId, setLocalCarrierId] = useState(carrierId);
    const [localProviderId, setLocalProviderId] = useState(providerId);
    const [localHubId, setLocalHubId] = useState(hubId);
    const [localFacilityId, setLocalFacilityId] = useState(facilityId);
    const [localOther, setLocalOther] = useState(other);
    const [localReferenceNumber, setLocalReferenceNumber] = useState(referenceNumber);
    const [localDue, setLocalDue] = useState(due);
    const [localDeadline, setLocalDeadline] = useState(deadline);
    const [localProgress, setLocalProgress] = useState(progress);
    const [localArchived, setLocalArchived] = useState(archived);
    const [isSaving, setIsSaving] = useState(false);
  
    const [newActivity, setNewActivity] = useState('');

    const [showNewAttestationDateModal, setShowNewAttestationDateModal] = useState(false);
    const [newAttestationDate, setNewAttestationDate] = useState('');

    const [showNewExpirationDateModal, setShowNewExpirationDateModal] = useState(false);
    const [newExpirationDate, setNewExpirationDate] = useState('');
    const [newEffectiveDate, setNewEffectiveDate] = useState('');
    const [sendEmailNotification, setSendEmailNotification] = useState(false);
  
    const { isH3Admin, authorities } = useContext(UserSettingsContext);
    const canEdit = isH3Admin || authorities.includes("TASK_EDIT");
  
    const notyf = useContext(NotyfContext);
  
    // On create, may not touch these
    if (localStatus == null) {
      setLocalStatus("CREATED")
    }
  
    if (localCategory == null) {
      setLocalCategory("INITIAL_ENROLLMENT");
    }
  
    if (localOther == null) {
      setLocalOther("");
    }

    if (localReferenceNumber == null) {
      setLocalReferenceNumber("");
    }

    // This will always be touched, but react doesn't like us starting it off as null
    if (localDue == null) {
      let twoBizDays = new Date();
      twoBizDays.setDate(twoBizDays.getDate() + 2);

      if (twoBizDays.getDay() === 6 || twoBizDays.getDay() === 0)
      {
          twoBizDays.setDate(twoBizDays.getDate() + 2);
      }

      setLocalDue(twoBizDays.getFullYear() + "-" + String(twoBizDays.getMonth() + 1).padStart(2, '0') + "-" + String(twoBizDays.getDate()).padStart(2, '0'));
    }

    if (localDeadline == null) {
      setLocalDeadline("");
    }
  
    // Have to format the local assignees here to structure the plugin can understand
    const formattedAssignees = [];
    if (assignees != null)
    {
      for (var i=0; i<assignees.length; i++)
      {
        formattedAssignees.push({value: assignees[i].userId, label:assignees[i].firstName + ' ' + assignees[i].lastName});
      }
    }
    const [localAssignees, setLocalAssignees] = useState(formattedAssignees);
  
    const handleAssigneesChanged = (assignees) => {
      setLocalAssignees(assignees);
    }
  
    const handleStatusChanged = (status, e) => {
      if (e != null) {
        e.preventDefault();
      }
  
      setLocalStatus(status);
    }
  
    const handleCategoryChanged = (category, e) => {
      if (e != null) {
        e.preventDefault();
      }
  
      setLocalCategory(category);
    }
  
    const handlePracticeChanged = (practiceId, e) => {
      if (e != null) {
        e.preventDefault();
      }
  
      setLocalPracticeId(practiceId);
  
      // Clear the other values that are dependent on practice
      setLocalCarrierId(0);
      setLocalProviderId(0);
      setLocalHubId(0);
      setLocalFacilityId(0);
    }
  
    const handleCarrierChanged = (carrierId, e) => {
      if (e != null) {
        e.preventDefault();
      }
  
      setLocalCarrierId(carrierId);
    }
  
    const handleProviderChanged = (providerId, e) => {
      if (e != null) {
        e.preventDefault();
      }
  
      setLocalProviderId(providerId);
      setLocalHubId(0);
    }
  
    const handleHubChanged = (hubId, e) => {
      if (e != null) {
        e.preventDefault();
      }
  
      setLocalHubId(hubId);
    }
  
    const handleFacilityChanged = (facilityId, e) => {
        if (e != null) {
          e.preventDefault();
        }
    
        setLocalFacilityId(facilityId);
      }

    const handleOtherChanged = (other, e) => {
      if (e != null) {
        e.preventDefault();
      }
  
      setLocalOther(other);
    }

    const handleReferenceNumberChanged = (referenceNumber, e) => {
      if (e != null) {
        e.preventDefault();
      }
  
      setLocalReferenceNumber(referenceNumber);
    }
  
    const handleDueChanged = (due) => {
      setLocalDue(due);
    }

    const handleDeadlineChanged = (deadline, e) => {
      if (e != null) {
        e.preventDefault();
      }
  
      setLocalDeadline(deadline);
    }
  
    const handleProgressChanged = (progress, e) => {
      if (e != null) {
        e.preventDefault();
      }
  
      setLocalProgress(progress);
    }
    
    const handleArchivedChanged = (archived, e) => {
      if (e != null) {
        e.preventDefault();
      }
  
      setLocalArchived(archived);
    }
    
    const handleNewActivityChanged = (newActivity, e) => {
      if (e != null) {
        e.preventDefault();
      }
  
      setNewActivity(newActivity);
    }
  
    const reset = () => {
      setLocalStatus(status);
      setLocalCategory(category);
      setLocalPracticeId(practiceId);
      setLocalCarrierId(carrierId);
      setLocalProviderId(providerId);
      setLocalHubId(hubId);
      setLocalFacilityId(facilityId);
      setLocalOther(other);
      setLocalReferenceNumber(referenceNumber);
      setLocalDue(due);
      setLocalDeadline(deadline);
      setLocalProgress(progress);
      setLocalArchived(archived);
      setLocalAssignees(formattedAssignees);
      setNewActivity('');
      setIsSaving(false);
      setNewAttestationDate('');
      setNewExpirationDate('');
      setNewEffectiveDate('');
      setSendEmailNotification(false);
    }
  
    const handleCancel = () => {
      reset();
      onCancel();
    }
  
    const handleDelete = () => {
        if (window.confirm("Are you sure you want to delete this task?"))
        {
          authenticatedFetch({
            path: "task/" + id,
            additionalHeaders: {"taskId": id},
            method: "DELETE",
            successHandler: function(result) {
              onDelete();
  
              notyf.open({type: "error", message: "Deleted!", duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
            },
            errorHandler: function(error) {
              alert("Something went wrong; please try again");
            }
          });
        }
    }

    const doSubmit = (expirationOrAttestationModalSkipped=false) => {
      const requestData = {
        id: localId,
        status: localStatus,
        category: localCategory,
        practiceId: localPracticeId,
        carrierId: localCarrierId,
        providerId: localProviderId,
        hubId: localHubId,
        facilityId: localFacilityId,
        other: localOther,
        referenceNumber: localReferenceNumber,
        due: localDue,
        deadline: localDeadline,
        progress: localProgress,
        archived: localArchived,
        newActivity: newActivity,
        assignees: localAssignees,
        newAttestationDate: expirationOrAttestationModalSkipped ? null : newAttestationDate,
        newExpirationDate: expirationOrAttestationModalSkipped ? null : newExpirationDate,
        newEffectiveDate: expirationOrAttestationModalSkipped ? null : newEffectiveDate,
        sendEmailNotification: expirationOrAttestationModalSkipped ? false : sendEmailNotification
      };
  
      authenticatedFetch({
        path: "task",
        method: "POST",
        requestData: requestData,
        additionalHeaders: {"taskId": localId ? localId : null},
        successHandler: function(result) {
          setNewActivity('');
          setIsSaving(false);
  
          var message = "Updated!";
          if (localId == null || localId === 0)
          {
            message = "Created!";
            reset(); // If this is the shared "new" task modal, need to reset everything
          }
  
          onSave();
  
          notyf.open({type: "success", message: message, duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
        },
        errorHandler: function() {
          alert("Something went wrong; please try again");
          setNewActivity('');
          setIsSaving(false);
        }
      });
    }

    const onHideNewAttestationDateModal = () => {
      setShowNewAttestationDateModal(false);
      if (newAttestationDate !== null && newAttestationDate !== "")
      {
        doSubmit();
      } 
      else
      {
        setIsSaving(false);
      }
    }

    const onSkipNewAttestationDateModal = () => {
      setShowNewAttestationDateModal(false);
      doSubmit(true);
    }

    const onHideNewExpirationDateModal = () => {
      setShowNewExpirationDateModal(false);
      if (newExpirationDate !== null && newExpirationDate !== ""
        && (localCategory !== "INITIAL_ENROLLMENT" || (localCategory === "INITIAL_ENROLLMENT" && newEffectiveDate !== null && newEffectiveDate !== "")))
      {
        doSubmit();
      }
      else
      {
        setIsSaving(false);
      }
    }

    const onSkipNewExpirationDateModal = () => {
      setShowNewExpirationDateModal(false);
      doSubmit(true);
    }
  
    const handleSave = () => {
      if (localPracticeId === null || localPracticeId === 0)
      {
        alert("Please select a practice");
        return;
      }
  
      if (localProgress != null && (localProgress < 0 || localProgress > 100))
      {
        alert("Please indicate a valid progress value (0-100, or blank)");
        return;
      }

      setIsSaving(true);
  
      if (localStatus === "COMPLETE" && status !== "COMPLETE")
      {
        if (localCategory === "ATTESTATION" && localHubId && localHubId !== 0)
        {
          setShowNewAttestationDateModal(true);
          return;
        }
        else if ((localCategory === "INITIAL_ENROLLMENT" || localCategory === "ANNUAL_ENROLLMENT") && ((localCarrierId && localCarrierId !== 0 && localCarrierId !== '0') || (localFacilityId && localFacilityId !== 0 && localFacilityId !== '0')))
        {
          if (localCategory === "INITIAL_ENROLLMENT")
          {
            // Default to true
            setSendEmailNotification(true);
          }
          else
          {
            setSendEmailNotification(false);
          
          }

          setShowNewExpirationDateModal(true);
          return;
        }
      }

      doSubmit();
    }
  
    let activityRows = [];
    if (activityList) {
      activityRows.push(activityList.map((activity, i) => {
        return (
          <ActivityRow key={activity.id} user={activity.userFormattedName} notes={activity.notes} created={activity.created} />
        )
      }, this));
    }
  
    let statusOptionList = rawTaskStatuses.filter((status) => { return status.value !== "" }).map((status, i) => {
        return (
          <option value={status.value} key={status.value}>{status.label}</option>
        )
      }, this);
  
    return (
      <>
        <Modal show={show} onHide={handleCancel} centered>
          <Modal.Header closeButton style={{backgroundColor: '#DEF2F1'}}>
            <Modal.Title>
              {id > 0 ? "Task Details for (" + id  + ")": "Create New Task"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table size="sm" striped> 
              <tbody>
                <tr>
                  <td>Status</td>
                  <td>
                    <Form.Select disabled={!canEdit} onChange={(e) => {handleStatusChanged(e.target.value, e)}} value={localStatus}>
                      {statusOptionList}
                    </Form.Select>
                  </td>
                </tr>
                <tr>
                  <td>Category</td>
                  <td>
                    <Form.Select disabled={!canEdit} onChange={(e) => {handleCategoryChanged(e.target.value, e)}} value={localCategory}>
                      <option value="INITIAL_ENROLLMENT" key="INITIAL_ENROLLMENT">Initial Enrollment</option>
                      <option value="ANNUAL_ENROLLMENT" key="ANNUAL_ENROLLMENT">Re-enrollment</option>
                      <option value="ATTESTATION" key="ATTESTATION">Attestation</option>
                      <option value="INQUIRY" key="INQUIRY">Inquiry</option>
                      <option value="TERMINATION" key="TERMINATION">Termination</option>
                      <option value="OTHER" key="OTHER">Other</option>
                    </Form.Select>
                  </td>
                </tr>
                <tr><td>Practice</td><td><PracticeSelect taskId={id} disabled={!canEdit} selectedPracticeId={localPracticeId} handlePracticeChanged={handlePracticeChanged} /></td></tr>
                <tr><td>Carrier</td><td><CarrierSelect taskId={id} disabled={!canEdit} practiceId={localPracticeId} selectedCarrierId={localCarrierId} handleCarrierChanged={handleCarrierChanged} /></td></tr>
                <tr><td>Provider</td><td><ProviderSelect taskId={id} disabled={!canEdit} practiceId={localPracticeId} selectedProviderId={localProviderId} handleProviderChanged={handleProviderChanged} /></td></tr>
                <tr><td>Data Hub</td><td><HubSelect taskId={id} disabled={!canEdit} providerId={localProviderId} practiceId={localPracticeId} selectedHubId={localHubId} handleHubChanged={handleHubChanged} /></td></tr>
                <tr><td>Location</td><td><FacilitySelect taskId={id} disabled={!canEdit} practiceId={localPracticeId} selectedFacilityId={localFacilityId} handleFacilityChanged={handleFacilityChanged} /></td></tr>
                <tr><td>Other</td><td><Form.Control disabled={!canEdit} onChange={(e) => {handleOtherChanged(e.target.value, e)}} value={localOther} /></td></tr>
                <tr><td>Reference #</td><td><Form.Control disabled={!canEdit} onChange={(e) => {handleReferenceNumberChanged(e.target.value, e)}} value={localReferenceNumber} /></td></tr>
                <tr>
                  <td>Assignees</td>
                  <td><TaskAssigneeSelect taskId={id} disabled={!canEdit} selectedAssignees={localAssignees} handleAssigneesChanged={handleAssigneesChanged} /></td>
                </tr>
                <tr>
                  <td>Next Action</td>
                  <td>
                    {
                    isH3Admin ? 
                    <NextActionDatePicker taskId={id} disabled={!canEdit} localDue={localDue} handleDueChanged={handleDueChanged} localAssignees={localAssignees}/>
                    :
                    <Form.Control disabled={!canEdit} onChange={(e) => {handleDueChanged(e.target.value)}} type="date" value={localDue} placeholder="Next action date" />
                    }

                    
                  </td>
                </tr>
                <tr>
                  <td>Deadline</td>
                  <td>
                    <Form.Control disabled={!canEdit} onChange={(e) => {handleDeadlineChanged(e.target.value, e)}} type="date" value={localDeadline} placeholder="Deadline" />
                  </td>
                </tr>
                <tr>
                  <td>Progress (%)</td>
                  <td><Form.Control disabled={!canEdit} onChange={(e) => {handleProgressChanged(e.target.value, e)}} type="number" min="0" max="100" onWheel={(e) => e.target.blur()} value={localProgress} /></td>
                </tr>
                {status === "COMPLETE" &&
                <>
                <tr>
                  <td>Archived</td>
                  <td><Form.Check disabled={!canEdit} onChange={(e) => {handleArchivedChanged(e.target.checked, e)}}  value={localArchived} /></td>
                </tr>
                <tr></tr>
                </>
                }
                <tr>
                  <td>Notes</td>
                  <td>
                    <div style={{maxHeight: '200px', overflow: 'auto', display: 'block', width: '100%'}}>
                    <Table striped>
                      <tbody>
                        {canEdit ?
                        <tr><td><Form.Control key={0} onChange={(e) => {handleNewActivityChanged(e.target.value, e)}} value={newActivity} as="textarea" placeholder="Add new note"/></td></tr>
                        : '' }
                        {activityRows}
                      </tbody>
                    </Table>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer style={{backgroundColor: '#DEF2F1'}}>
            { isSaving ? 
              <Spinner animation="border" size="sm" variant="primary" className="float-end" style={{marginRight: '40px', marginTop: '11px', marginBottom: '11px'}}/>
              :
              <>
                {id != null && id > 0 && isH3Admin ? <Button className="me-auto btn-secondary" onClick={handleDelete}>Delete</Button> : ''}
                <Button onClick={handleCancel}>{canEdit ? 'Cancel' : 'Close'}</Button>
                {canEdit ?
                <Button onClick={handleSave}>{id == null || id <= 0 ? "Create Task" : "Update Task"}</Button>
                : '' }
              </>
            }
          </Modal.Footer>
        </Modal>
      <NewAttestationDateModal show={showNewAttestationDateModal} onClose={onHideNewAttestationDateModal} handleSkip={onSkipNewAttestationDateModal} newAttestationDate={newAttestationDate} handleNewAttestationDateChanged={(newAttestationDate) => setNewAttestationDate(newAttestationDate)} />
      <NewExpirationDateDateModal show={showNewExpirationDateModal} onClose={onHideNewExpirationDateModal} handleSkip={onSkipNewExpirationDateModal} newExpirationDate={newExpirationDate} handleNewExpirationDateChanged={(newExpirationDate) => setNewExpirationDate(newExpirationDate)} showNewEffectiveDate={localCategory === "INITIAL_ENROLLMENT"} newEffectiveDate={newEffectiveDate} handleNewEffectiveDateChanged={(newEffectiveDate) => setNewEffectiveDate(newEffectiveDate)} showSendEmailNotification={!!((localCategory === "INITIAL_ENROLLMENT" || localCategory === "ANNUAL_ENROLLMENT") && (localProviderId && localProviderId !== 0 && localProviderId !== '0') && (localCarrierId && localCarrierId !== 0 && localCarrierId !== '0'))} sendEmailNotification={sendEmailNotification} handleSendEmailNotificationChanged={(sendEmailNotification) => setSendEmailNotification(sendEmailNotification)}/>
      </>
    );
}

  export default TaskModal;