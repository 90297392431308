import React, {useEffect, useState} from "react";
import { Form, Table, ButtonGroup, ToggleButton, Spinner } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserNurse,
  faBriefcaseMedical,
  faRunning
} from "@fortawesome/free-solid-svg-icons";

import EditableExpires from "../../utils/EditableExpires";

const { REACT_APP_BACKEND_URL } = process.env;

const FacilitySelect = ({ token, selectedFacilityId, handleFacilityChanged}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (token != null && token !== "")
    {
      fetch(REACT_APP_BACKEND_URL + "privileges/practice/" + token + "/facilities")
      .then(res => {
          if (res.ok)
          {
              return res.json();
          }
    
          throw Error(res.status);
      })
      .then(
        (result) => {
          setIsLoaded(true);
          setData(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
    }
    else
    {
      setIsLoaded(false);
      setData([]);
    }
  }, [token])


  if (!isLoaded)
  {
    return (<Form.Select style={{height: "36px"}} disabled={true}><option value={0} key={0}>Loading...</option></Form.Select>);
  }
  else if (error || !data)
  {
    return (<Form.Select style={{height: "36px"}} disabled={true}><option value={0} key={0}>Error... Please reload the page...</option></Form.Select>);
  }
  else
  {
    let facilityOptionList = [<option value={0} key={0}>Select a facility...</option>];
    facilityOptionList.push(data.map((facility, i) => {
      return (
        <option value={facility.id} key={facility.id}>{facility.name}</option>
      )
    }, this));

    return (
      <Form.Select style={{height: "36px"}} onChange={(e) => {handleFacilityChanged(e)}} value={selectedFacilityId}>{facilityOptionList}</Form.Select>
    )
  }
}

const ProviderSelect = ({ token, selectedProviderId, handleProviderChanged}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (token != null && token !== "")
    {
      fetch(REACT_APP_BACKEND_URL + "privileges/practice/" + token + "/providers")
      .then(res => {
          if (res.ok)
          {
              return res.json();
          }
    
          throw Error(res.status);
      })
      .then(
        (result) => {
          setIsLoaded(true);
          setData(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
    }
    else
    {
      setIsLoaded(false);
      setData([]);
    }
  }, [token])


  if (!isLoaded)
  {
    return (<Form.Select style={{height: "36px"}} disabled={true}><option value={0} key={0}>Loading...</option></Form.Select>);
  }
  else if (error || !data)
  {
    return (<Form.Select style={{height: "36px"}} disabled={true}><option value={0} key={0}>Error... Please reload the page...</option></Form.Select>);
  }
  else
  {
    let providerOptionList = [<option value={0} key={0}>Select a provider...</option>];
    providerOptionList.push(data.map((provider, i) => {
      return (
        <option value={provider.id} key={provider.id}>{provider.name}</option>
      )
    }, this));

    return (
      <Form.Select style={{height: "36px"}} onChange={(e) => {handleProviderChanged(e)}} value={selectedProviderId}>{providerOptionList}</Form.Select>
    )
  }
}

const ProviderList = ({ token, facilityId}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (token != null && token !== "" && facilityId > 0)
    {
      fetch(REACT_APP_BACKEND_URL + "privileges/practice/" + token + "/facility/" + facilityId + "/providers")
      .then(res => {
          if (res.ok)
          {
              return res.json();
          }
    
          throw Error(res.status);
      })
      .then(
        (result) => {
          setIsLoaded(true);
          setData(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
    }
    else
    {
      setIsLoaded(false);
      setData([]);
    }
  }, [token, facilityId])


  if (facilityId <= 0)
  {
    return (<span>Please first select a facility...</span>);
  }
  else if (!isLoaded)
  {
    return (<span>Loading...</span>);
  }
  else if (error || !data)
  {
    return (<span>Error... Please reload the page...</span>);
  }
  else
  {
    let providerList = data.map((provider, i) => {
      var icon = faRunning;
      if (provider.isPhysician)
      {
        icon = faBriefcaseMedical;
      }
      else if (provider.isApp)
      {
        icon = faUserNurse
      }

      return (
        <tr key={i}>
          <td><FontAwesomeIcon icon={icon} style={{height: "16px", width: "16px", color: "#2B7A78"}} /></td>
          <td> {provider.lastName}</td>
          <td>{provider.firstName}</td>
          <td>{provider.middleName}</td>
          <td>{provider.credentials}</td>
        </tr>
      )
    }, this);

    if (providerList.length === 0)
    {
      return (
        <span>No active providers found</span>
      );
    }

    return (
      <Table striped hover className="table-sm">
      <thead>
        <tr>
          <td colSpan={5}><b>Providers</b></td>
        </tr>
        <tr>
          <td>&nbsp;</td>
          <td>Last</td>
          <td>First</td>
          <td>Middle</td>
          <td>Credentials</td>
        </tr>
      </thead>
      <tbody>
        {providerList}   
      </tbody>
    </Table>
    );
  }
}

const FacilityList = ({ token, providerId}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (token != null && token !== "" && providerId > 0)
    {
      fetch(REACT_APP_BACKEND_URL + "privileges/practice/" + token + "/provider/" + providerId + "/facilities")
      .then(res => {
          if (res.ok)
          {
              return res.json();
          }
    
          throw Error(res.status);
      })
      .then(
        (result) => {
          setIsLoaded(true);
          setData(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
    }
    else
    {
      setIsLoaded(false);
      setData([]);
    }
  }, [token, providerId])


  if (providerId <= 0)
  {
    return (<span>Please first select a provider...</span>);
  }
  else if (!isLoaded)
  {
    return (<span>Loading...</span>);
  }
  else if (error || !data)
  {
    return (<span>Error... Please reload the page...</span>);
  }
  else
  {
    let facilityList = data.map((facility, i) => {
      return (
        <tr key={i}>
          <td>{facility.name}</td>
          <td>{facility.city}</td>
          <td>{facility.zip}</td>
          <td>{facility.effectiveDate && <EditableExpires localExpires={facility.effectiveDate} warnWithColors={false} localActive={true} editingExpires={false} />}</td>
          <td>{facility.expires && <EditableExpires localExpires={facility.expires} localActive={true} editingExpires={false} />}</td>
        </tr>
      )
    }, this);

    if (facilityList.length === 0)
    {
      return (
        <span>No active facilities found</span>
      );
    }

    return (
      <Table striped hover className="table-sm">
      <thead>
        <tr>
          <td colSpan={5}><b>Facilities</b></td>
        </tr>
        <tr>
          <td>Name</td>
          <td>City</td>
          <td>State</td>
          <td>Effective Date</td>
          <td>Next Renewal</td>
        </tr>
      </thead>
      <tbody>
        {facilityList}   
      </tbody>
    </Table>
    );
  }
}

const DropdownFacilityLookup = ({token}) => {
  const[selectedFacilityId, setSelectedFacilityId] = useState(0);

  const handleFacilityChanged = (e) => {
    e.preventDefault();
    setSelectedFacilityId(parseInt(e.target.value));
  }

  return (
    <>
      <Table className="table-borderless table-sm">
        <tbody>
          <tr>
            <td> <FacilitySelect token={token} selectedFacilityId={selectedFacilityId} handleFacilityChanged={handleFacilityChanged} /></td>
          </tr>

        { selectedFacilityId !== 0 &&
          <tr>
            <td colSpan={2}>
              <br />
              <ProviderList token={token} facilityId={selectedFacilityId} />
            </td>
          </tr>
        }
        </tbody>
      </Table>
    </>
  );
}

const DropdownProviderLookup = ({token}) => {
  const[selectedProviderId, setSelectedProviderId] = useState(0);

  const handleProviderChanged = (e) => {
    e.preventDefault();
    setSelectedProviderId(parseInt(e.target.value));
  }

  return (
    <>
      <Table className="table-borderless table-sm">
        <tbody>
          <tr>
            <td> <ProviderSelect token={token} selectedProviderId={selectedProviderId} handleProviderChanged={handleProviderChanged} /></td>
          </tr>

        { selectedProviderId !== 0 &&
          <tr>
            <td colSpan={2}>
              <br />
              <FacilityList token={token} providerId={selectedProviderId} />
            </td>
          </tr>
        }
        </tbody>
      </Table>
    </>
  );
}

const PrivilegesBySelect = ({ selectedPrivilegesBy, handlePrivilegesByChanged}) => {
  return (
      <ButtonGroup className="me-auto">
          <ToggleButton
              key="PROVIDER"
              id="PROVIDER"
              type="radio"
              variant="outline-primary"
              style={{color: selectedPrivilegesBy === "PROVIDER" ? "white" : ""}}
              value="PROVIDER"
              checked={selectedPrivilegesBy === "PROVIDER"}
              onChange={(e) => {handlePrivilegesByChanged(e.target.value)}}
          >By Provider</ToggleButton>
          <ToggleButton
              key="FACILITY"
              id="FACILITY"
              type="radio"
              variant="outline-primary"
              style={{color: selectedPrivilegesBy === "FACILITY" ? "white" : ""}}
              value="FACILITY"
              checked={selectedPrivilegesBy === "FACILITY"}
              onChange={(e) => {handlePrivilegesByChanged(e.target.value)}}
          >By Facility</ToggleButton>
      </ButtonGroup>
      
  )
}

const NetworkLookup = ({by, token}) => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);

  const handlePrivilegesByChanged = (privilegesBy) => {
    setLoading(true);
    window.location = "/privileges/" + privilegesBy.toLowerCase() + "/" + token;
  }

  // if (searchParams.get("version") === "search")
  // {
  //   return <SearchNetworkLookup token={token} />
  // }

  return (
    <>
      <div className="float-end" style={{marginTop: "-15px"}}>
        {loading ? <Spinner size="sm" animation="border" style={{marginRight: "70px", marginTop: "5px"}} /> : <PrivilegesBySelect selectedPrivilegesBy={by.toUpperCase()} handlePrivilegesByChanged={handlePrivilegesByChanged}/>}
      </div>
      <br /><br />
      {by === "provider" ? <DropdownProviderLookup token={token} /> : <DropdownFacilityLookup token={token} />}
    </>
  )
};


export default NetworkLookup;
