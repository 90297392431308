import React, { useState, useEffect, useContext } from "react";
import { Card, Table, Button, Form} from "react-bootstrap";
import NotyfContext from "../contexts/NotyfContext";
import { X, Check, Edit2, Trash2} from "react-feather";

import { useTable, useSortBy, useFilters} from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";

import EditableExpires from "./EditableExpires";
import authenticatedFetch from "../utils/fetch"
import {caseInsensitiveSort}  from "../utils/reactTableUtils";


const PeerReference = ({ id, providerId, lastName, firstName, credentials, notes, expirationDate, refreshList, canEdit, onCancelEdit, startInEditMode=false }) => {
  const [editing, setEditing] = useState(startInEditMode);
  const [localLastName, setLocalLastName] = useState(lastName);
  const [localFirstName, setLocalFirstName] = useState(firstName);
  const [localCredentials, setLocalCredentials] = useState(credentials);
  const [localNotes, setLocalNotes] = useState(notes);
  const [localExpirationDate, setLocalExpirationDate] = useState(expirationDate);
  
  const notyf = useContext(NotyfContext);


  function handleDeleteClicked(e) {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete this peer reference?"))
    {
      authenticatedFetch({
        method: "DELETE",
        path: "peerreference/" + id,
        successHandler: function(result) {
          refreshList();
        },
        errorHandler: function(error) {
          notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
        }
      });
    }
  }

  function handleEditClicked(e) {
    e.preventDefault();
    setEditing(true);
  }

  function cancelEdit(e) {
    e.preventDefault();
    setEditing(false);
    setLocalLastName(lastName);
    setLocalFirstName(firstName);
    setLocalCredentials(credentials);
    setLocalNotes(notes);
    setLocalExpirationDate(expirationDate);

    if (onCancelEdit)
    {
      onCancelEdit();
    }
  }

  function saveEdit(e) {
    e.preventDefault();

    if (localLastName === '' && localFirstName === '')
    {
      alert("Please enter a first or last name!");
      return;
    }

    setEditing(false);

    if (lastName !== localLastName || firstName !== localFirstName || credentials !== localCredentials || notes !== localNotes || localExpirationDate !== expirationDate)
    {
      authenticatedFetch({
        method: id ? "PUT" : "POST",
        requestData: {providerId: parseInt(providerId), lastName: localLastName, firstName: localFirstName, credentials: localCredentials, notes: localNotes, expirationDate: localExpirationDate},
        path: id ? "peerreference/" + id  : "peerreference",
        successHandler: function(result) {
          refreshList();
          notyf.open({type: "success", message: id ? "Updated!" : "Saved!", duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
        },
        errorHandler: function() {
          setEditing(true);
          setLocalLastName(lastName);
          setLocalFirstName(firstName);
          setLocalCredentials(credentials);
          setLocalNotes(notes);
          setLocalExpirationDate(expirationDate);
          notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
        }
      });
    }
  }


  function handleLastNameChanged(e) {
    e.preventDefault();
    setLocalLastName(e.target.value);
  }

  function handleFirstNameChanged(e) {
    e.preventDefault();
    setLocalFirstName(e.target.value);
  }

  function handleCredentialsChanged(e) {
    e.preventDefault();
    setLocalCredentials(e.target.value);
  }

  function handleNotesChanged(e) {
    e.preventDefault();
    setLocalNotes(e.target.value);
  }

  function handleExpirationDateChanged(val, e) {
    e.preventDefault();
    setLocalExpirationDate(val);
  }

  var notesReadOnly = '';
  if (localNotes && localNotes.length > 0) {
    notesReadOnly = localNotes.split("\n").map(function(item, idx) {
      return ( 
          <span key={idx}>
              {item}
              <br />
          </span>
       )
    });
  }

  return (
    <>
    {editing ?
    <td>
      <Form.Control size="sm" autoFocus={true} maxLength={255} value={localLastName} onChange={(e) => {handleLastNameChanged(e)}} />
    </td>
    :
    <td>
      {localLastName}
    </td>
    }
    {editing ?
    <td>
      <Form.Control size="sm" maxLength={255} value={localFirstName} onChange={(e) => {handleFirstNameChanged(e)}} />
    </td>
    :
    <td>
      {localFirstName}
    </td>
    }
    {editing ?
    <td>
      <Form.Control size="sm" maxLength={255} value={localCredentials} onChange={(e) => {handleCredentialsChanged(e)}} />
    </td>
    :
    <td>
      {localCredentials}
    </td>
    }
    {editing ?
    <td>
      <Form.Control style={{height: "180px"}} size="sm" as="textarea" maxLength={511} value={localNotes} onChange={(e) => {handleNotesChanged(e)}} />
    </td>
    :
    <td>
      {notesReadOnly}
    </td>
    }
    <td>
      <EditableExpires localExpires={localExpirationDate} localActive={true} editingExpires={editing} handleExpiresChanged={handleExpirationDateChanged} />
    </td>
    <td style={{width: '70px'}}>
      { canEdit?
        <>
        {editing ? 
        <>
          <X style={{'height': '16px', 'width': '16px'}} cursor="pointer" onClick={(e) => {cancelEdit(e)}} />
          &nbsp;<Check style={{'height': '16px', 'width': '16px'}} cursor="pointer" onClick={(e) => {saveEdit(e)}} className="text-success"/>
        </>
        :
        <>
          <Edit2 style={{'height': '12px', 'width': '12px'}} cursor="pointer" onClick={(e) => {handleEditClicked(e)}}/>&nbsp;&nbsp;
          <Trash2 style={{'height': '12px', 'width': '12px'}} cursor="pointer" onClick={(e) => {handleDeleteClicked(e)}}/>
        </>
        }
        </>
        : <>&nbsp;</>
      }
    </td>
  </>
  )
}


const PeerReferencesTable = ({providerId, addingNew, onCancelAddNew, list, isLoaded, error, canEdit, refreshList}) => {
  const data = list;

  const columns = React.useMemo(
    () => [
      {
        accessor: "id",
        key: "id",
      },
      {
        Header: "Last Name",
        accessor: "lastName",
        key: "lastName",
      },
      {
        Header: "First Name",
        accessor: "firstName",
        key: "firstName",
      },
      {
        Header: "Credentials",
        accessor: "credentials",
        key: "credentials",
      },
      {
        Header: "Details",
        accessor: "notes",
        key: "notes",
      },
      {
        Header: "Expiration Date",
        accessor: "expirationDate",
        key: "expirationDate",
      },
      {
        Header: "",
        accessor: "icons",
        key: "icons",
      },
    ],
  []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
  useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
      autoResetFilters: false,
      initialState: {
        hiddenColumns: ['id'],
        sortBy: [
            // {
            //     id: 'expirationDate',
            //     desc: false,
            // },
            // Weird behavior if you don't have any expiration dates
            {
                id: 'lastName',
                desc: false
            },
            {
              id: 'firstName',
              desc: false
          }
        ],
      },
      sortTypes: {
        alphanumeric: (row1, row2, columnName) => caseInsensitiveSort({row1, row2, columnName})
      }
    },
    useFilters,
    useSortBy,
  );

  return (
    <Table className="table-sm" striped bordered hover {...getTableProps()}>
    <thead>
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => {
            const { key, ...restHeaderProps } = (column.id === "icons" || column.id === "notes") ? column.getHeaderProps() : column.getHeaderProps(column.getSortByToggleProps());
            return (
              <th {...restHeaderProps} key={key}>
                {column.render("Header")}
                {(column.id !== "icons" && column.id !== "notes") ? (
                  <>
                  {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FontAwesomeIcon icon={faSortDown} className="ms-2" />
                      ) : (
                        <FontAwesomeIcon icon={faSortUp} className="ms-2" />
                      )
                    ) : (
                      <FontAwesomeIcon icon={faSort} className="ms-2" />
                  )}
                  </>
                ) : <></>
                }
              </th>
            )
          }
          )}
        </tr>
      ))}
    </thead>
    <tbody {...getTableBodyProps()}>
      {
      error ? (<tr><td colSpan={4}>Something went wrong; please reload the page...</td></tr>) : ( 
        !isLoaded ? (<tr><td colSpan={4}>Loading...</td></tr>) : (
          rows.map((row) => {
          prepareRow(row);
          const { key, ...restRowProps } = row.getRowProps();
          return (
            <tr {...restRowProps} key={key}>
              <PeerReference providerId={providerId} id={row.values.id} key={row.values.id} lastName={row.values.lastName ? row.values.lastName : ""} firstName={row.values.firstName ? row.values.firstName : ""} credentials={row.values.credentials ? row.values.credentials : ""} notes={row.values.notes ? row.values.notes : ""} expirationDate={row.values.expirationDate} refreshList={refreshList} canEdit={canEdit}/>
            </tr>
          );
        })))
      }
      {
      addingNew ?
        <tr><PeerReference providerId={providerId} key={0} startInEditMode={true} lastName={""} firstName={""} credentials={""} notes={""} expirationDate={null} onCancelEdit={onCancelAddNew} canEdit={canEdit} refreshList={refreshList}/></tr>
      : <></>
      }
    </tbody>
  </Table>
  )
}

const PeerReferences = ({providerId, canEdit}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [addingNew, setAddingNew] = useState(false);

  useEffect(() => {
    refreshList();
  }, [providerId])

  function refreshList()
  {
    setAddingNew(false);
    authenticatedFetch({
      path: "peerreference/provider/" + providerId,
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function() {
        setIsLoaded(true);
        setError(true);
      }
    });
  }

  const handleAddNewClicked = (e) => {
    e.preventDefault();
  
    setAddingNew(true);
  }

  const onCancelAddNew = () => {
    setAddingNew(false);
  }

  var list = [];
  if (isLoaded)
  {
    list = data.map((item, i) => {
      return {key: item.id, id: item.id, lastName: item.lastName, firstName: item.firstName, credentials: item.credentials, notes: item.notes, expirationDate: item.expirationDate, updated: item.updated, updatedBy: item.updatedBy}
    }, this);
  }

  return (
    <Card>
      <Card.Header style={{marginBottom: '-20px'}}>
        <Card.Title>Peer References
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <PeerReferencesTable list={list} addingNew={addingNew} onCancelAddNew={onCancelAddNew} providerId={providerId} error={error} isLoaded={isLoaded} canEdit={canEdit} refreshList={refreshList}/>
        {canEdit?
          <span className="float-end" style={{marginTop: '6px'}}>
            <Button size="sm" variant="primary" className="float-end" onClick={(e) => {handleAddNewClicked(e)}}>+ Add New Peer Reference</Button>
          </span>
          : <></>
          }
      </Card.Body>
    </Card>
  )
}

export default PeerReferences;
