import React from "react";
import * as Yup from "yup";
import { useFormikContext, Formik } from "formik";
import { Button, Form } from "react-bootstrap";
import { Trash2} from "react-feather";
import { Flag } from "react-feather";
import getOnboardingStages from "../../utils/onboardingStages";
import Spinner from 'react-bootstrap/Spinner';

const { REACT_APP_BACKEND_URL } = process.env;


const AddTrainingButton = () => {
  const { values, setFieldValue } = useFormikContext();

  const handleAddClicked = (e) =>
  {
      e.preventDefault();
      setFieldValue("training", [
        ...values.training,
        {type: "", organization: "", startDate: "", endDate: "", notes: ""},
      ]);
  }

  return (
    <Button onClick={(e) => handleAddClicked(e)} size="sm">+ Add Another</Button>
  );
}

const RemoveTrainingButton = ({index}) => {
  const { values, setFieldValue } = useFormikContext();

  const handleRemoveClicked = (e) =>
  {
      e.preventDefault();
      setFieldValue("training", values.training.filter((_, i) => i !== index));
  }

  return (
    <Trash2 style={{'height': '12px', 'width': '12px'}} className="float-end" cursor="pointer" onClick={(e) => {handleRemoveClicked(e)}}/>
  );
}


const OnboardingTraining = ({stage, token, data}) => {
  var initialValues = {training:[]}

  if (data && data.training)
  {
    for (var i = 0; i < data.training.length; i++)
    {
      if (data.training[i].type == null)
      {
        data.training[i].type = "";
      }
      if (data.training[i].organization == null)
      {
        data.training[i].organization = "";
      }
      if (data.training[i].startDate == null)
      {
        data.training[i].startDate = "";
      }
      if (data.training[i].endDate == null)
      {
        data.training[i].endDate = "";
      }
      if (data.training[i].notes == null)
      {
        data.training[i].notes = "";
      }
      if (data.training[i].documentFilename == null)
      {
        data.training[i].documentFilename = "";
      }

      data.training[i].document = null;

      initialValues.training.push(data.training[i]);
    }
  }

  for (i=initialValues.training.length; i < 2; i++)
  {
    initialValues.training.push({type: "", organization: "", startDate: "", endDate: "", notes: "", document: null, documentFilename: ""});
  }

  let onboardingStages = getOnboardingStages(data.preferences);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        training: Yup.array().of(
          Yup.object().shape({
            type: Yup.string().max(255).required("Program type is required"),
            organization: Yup.string().max(255).required("Organization is required"),
            startDate: Yup.date().required("Start date is required"),
            endDate: Yup.date().required("End date is required"),
            notes: Yup.string().max(2047).required("Degree and contact information are required"),
            documentFilename: Yup.string(),
            document: Yup.mixed()
          })
        )
      })}
      onSubmit={async (values, { setErrors, setStatus, resetForm, setSubmitting }) => {
        try {
          for (var i = 0; i < values.training.length; i++)
          {
            // Set the document filename to the file we're sending so we can link the two up on the backend; preserve the old, if any, so we can delete
            values.training[i].oldDocumentFilename = values.training[i].documentFilename;
            if (values.training[i].document !== null)
            {
              values.training[i].documentFilename = values.training[i].document.name;
            }
          }

          var formData = new FormData();
          formData.append("data", new Blob([JSON.stringify(
            {
              training: values.training
            })], {
              type: "application/json"
          }));

          for (i = 0; i < values.training.length; i++)
          {
            if (values.training[i].document !== null)
            {
              formData.append("files", values.training[i].document);
            }
          }

          const requestMetadata = {
            method: 'POST',
            body: formData
          };

          const res = await fetch(REACT_APP_BACKEND_URL + "onboarding/" + stage + "/" + token, requestMetadata);

          if (res.status >= 200 && res.status <= 299)
          {
            setStatus({success: true})

            window.location = "/onboarding/" + onboardingStages[onboardingStages.indexOf(stage) + 1] + "/" + token
          }
          else
          {
            throw Error(res.statusText);
          }
        } catch (error) {
          const message = "Something went wrong; please try again";

          setStatus({ success: false });
          alert(message);
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        status,
        touched,
        values,
        getFieldProps,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label style={{fontWeight: "bold"}}>Education and Training*
            <br /><span style={{fontSize: '0.9em', fontWeight: "normal"}}><Flag style={{height: "16px", width: "16px", color: "#2B7A78"}} /> Please include all education, from undergraduate through fellowship. Diploma/completion certificate upload is required for medical school, internship, residency, and fellowship. For APPs, please include preceptorships and/or clinical rotations.</span>
            </Form.Label><br />
            {values.training.map((training, index) => {
              
              return (
                <div key={index}>
                <i>#{index +1}</i>
                {index > 1 && <RemoveTrainingButton index={index} />}
                
                <br />
                <Form.Control
                  placeholder="Program (e.g. undergrad, residency, etc.)"
                  {...getFieldProps(`training[${index}].type`)}
                  isInvalid={Boolean(touched.training?.[index]?.type && errors.training?.[index]?.type)}
                />
                {!!touched.training?.[index]?.type && (
                  <Form.Control.Feedback type="invalid">
                    {errors.training?.[index]?.type}
                  </Form.Control.Feedback>
                )}
                <br />
                <Form.Control
                  placeholder="Organization / School"
                  {...getFieldProps(`training[${index}].organization`)}
                  isInvalid={Boolean(touched.training?.[index]?.organization && errors.training?.[index]?.organization)}
                />
                {!!touched.training?.[index]?.organization && (
                  <Form.Control.Feedback type="invalid">
                    {errors.training?.[index]?.organization}
                  </Form.Control.Feedback>
                )}
                <br />
                <Form.Label style={{fontSize: "0.9em"}}>Start Date</Form.Label>
                <Form.Control
                  type="date"
                  {...getFieldProps(`training[${index}].startDate`)} 
                  isInvalid={Boolean(touched.training?.[index]?.startDate && errors.training?.[index]?.startDate)}
                />
                {!!touched.training?.[index]?.startDate && (
                  <Form.Control.Feedback type="invalid">
                    {errors.training?.[index]?.startDate}
                  </Form.Control.Feedback>
                )}
                <br />
                <Form.Label style={{fontSize: "0.9em"}}>End Date</Form.Label>
                <Form.Control
                  type="date"
                  {...getFieldProps(`training[${index}].endDate`)} 
                  isInvalid={Boolean(touched.training?.[index]?.endDate && errors.training?.[index]?.endDate)}
                />
                {!!touched.training?.[index]?.endDate && (
                  <Form.Control.Feedback type="invalid">
                    {errors.training?.[index]?.endDate}
                  </Form.Control.Feedback>
                )}
                <br />
                <Form.Control
                  as="textarea"
                  style={{height: "100px"}}
                  placeholder="Organization complete contact information (including address, phone, and email address), *and* program director's name"
                  {...getFieldProps(`training[${index}].notes`)}
                  isInvalid={Boolean(touched.training?.[index]?.notes && errors.training?.[index]?.notes)}
                />
                {!!touched.training?.[index]?.notes && (
                  <Form.Control.Feedback type="invalid">
                    {errors.training?.[index]?.notes}
                  </Form.Control.Feedback>
                )}
                <br />
                {values.training?.[index]?.documentFilename && 
                <Form.Label>(Current: <span style={{wordBreak: "break-all"}}>{values.training?.[index]?.documentFilename}</span>)</Form.Label>
                }
                <Form.Control
                  type="hidden"
                  {...getFieldProps(`training[${index}].documentFilename`)}
                />
                <Form.Control
                  type="file"
                  name={`training[${index}].document`}
                  onBlur={handleBlur}
                  onChange={(event) => {
                    const file = event.currentTarget.files.length > 0 ? event.currentTarget.files[0] : null;
                    setFieldValue(`training[${index}].document`, file);
                  }}
                  isInvalid={Boolean(touched.training?.[index]?.document && errors.training?.[index]?.document)}
                />
                {!!touched.training?.[index]?.document && (
                  <Form.Control.Feedback type="invalid">
                    {errors.training?.[index]?.document}
                  </Form.Control.Feedback>
                )}
                <br />
                </div>
            );
            })}
            <AddTrainingButton />
          </Form.Group>
          <br />
          {isSubmitting ?
          <div className="text-center">
             <br />
            <Spinner size="sm" animation="border" />
          </div>
          :
          <>
            <div className="text-center mt-3 float-start">
              <Button size="lg" className="btn-secondary" onClick={() => {window.location = "/onboarding/" + onboardingStages[onboardingStages.indexOf(stage) - 1] + "/" + token}}>Back</Button>
            </div>
            <div id="submitButton" className="text-center mt-3 float-end">
              <Button
                type="submit"
                variant="primary"
                size="lg"
                disabled={isSubmitting}  // Don't check touched, otherwise you end up with this button disabled for, e.g. a peer reference first name missing that has never been touched, so it's not in red; let them click this to touch everything
              >
                Save and Continue
              </Button>
            </div>
          </>
          }
          <br /><br /><br />
          {
            Object.keys(errors).length > 0 && Object.keys(touched).length > 0 &&
            <div className="text-center mt-3"><span className="invalid-feedback" style={{display: "inline-block"}}>Please correct the errors above before moving forward</span></div>
          }
        </Form>
      )}
    </Formik>
  );
}

export default OnboardingTraining;
